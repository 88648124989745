import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'

export default class NewPlantPurchase extends Model<{
  id: number
  created_at: string
  updated_at: string
  farm_id: number
  year: string
  plant: string
  date_purchased: string
  purchase_price: number
  plant_category_id: number
  period_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'new_plant_purchases'
  }
}
