/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/Product.json'

import ProductProject from './ProductProject'
import Project from './Project'

export default class Product extends Model {
  static entity = 'products'

  static primaryKey = 'id'

  entity = 'products'

  static fields () {
    return {
      id: this.number(null),
      title: this.string(null),

      projects: this.belongsToMany(Project, ProductProject, 'product_id', 'project_id'),
    }
  }

  declare id: number
  declare title: string

  declare projects: Project[]
}
