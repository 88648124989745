/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/Bill.json'

import User from './User'
import Project from './Project'
import EmployeeBill from './EmployeeBill'

export default class Bill extends Model {
  static entity = 'bills'

  static primaryKey = 'id'

  entity = 'bills'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      date_invoiced: this.attr(null),
      description: this.attr(null),
      status: this.string(null),
      billable_type: this.string(null),
      billable_id: this.number(null),

      employee: this.belongsTo(User, 'employee_id'),
      billable: this.morphTo([Project], 'billable_id', 'billable_type'),
      employee_bills: this.hasMany(EmployeeBill, 'bill_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare date_invoiced: string
  declare description: string
  declare status: string
  declare billable_type: string
  declare billable_id: number

  declare employee: User
  declare billable: unknown
  declare employee_bills: EmployeeBill[]
}
