import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import RainGauge from './RainGauge'

export default class Rainfall extends Model<{
  total_mm: number
  date: string
  rain_gauge_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'rainfalls'
  }

  public rain_gauge (): BelongsTo<RainGauge> {
    return new BelongsTo(RainGauge, this)
  }
}
