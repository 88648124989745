/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/LivestockInventoryCollection.json'

export default class LivestockInventoryCollection extends Model {
  static entity = 'livestock_inventory_collections'

  static apiEntity = 'livestock_inventories'

  static primaryKey = 'id'

  entity = 'livestock_inventory_collections'

  apiEntity = 'livestock_inventories'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      enterprise: this.string(null),
      farm_id: this.number(null),
      year: this.string(null),
      class_of_stock: this.string(null),
      opening_number: this.number(null),
      opening_average_weight: this.number(null),
      closing_number: this.number(null),
      closing_average_weight: this.number(null),
      notes: this.string(null),
      period_id: this.number(null),
      enterprise_id: this.number(null),

    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare enterprise: string
  declare farm_id: number
  declare year: string
  declare class_of_stock: string
  declare opening_number: number
  declare opening_average_weight: number
  declare closing_number: number
  declare closing_average_weight: number
  declare notes: string
  declare period_id: number
  declare enterprise_id: number
}
