import { FarmProperty as Model } from '../models'
import { orionFarmProperty as Api } from '..'
import { repoFactory } from '../repositories/GenericRepository'
import { useRepo } from 'pinia-orm'

export function useFarmPropertyRepo () {
  return useRepo(repoFactory(Model, Api))
}

export function fetchFarmProperty () {
  return useFarmPropertyRepo().fetch()
}
