import type { RouteRecordRaw } from 'vue-router'
import croppingRoutes from './croppingRoutes'
import livestockRoutes from './livestockRoutes'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/ClientLayout.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('src/pages/IndexPage.vue'),
      },
      {
        path: 'datasilo',
        name: 'iframe.datasilo',
        component: () => import('src/pages/DataSiloEmbedPage.vue'),
      },
      {
        path: 'oldportal',
        name: 'iframe.oldportal',
        component: () => import('src/pages/OldPortalEmbedPage.vue'),
      },
      {
        path: 'apps/:slug/:version',
        name: 'apps',
        props: true,
        component: () => import('src/pages/AppsPage.vue'),
      },
      {
        path: 'collection/:farmId/period/:periodId/apps/:slug/:version',
        name: 'collection.apps',
        props: true,
        component: () => import('src/pages/AppsPage.vue'),
      },
      {
        path: 'impersonate',
        name: 'admin.impersonate',
        component: () => import('src/pages/ImpersonatePage.vue'),
        meta: { requiresAdmin: true },
      },
      {
        path: '/select_business',
        name: 'select.business',
        component: () => import('src/pages/SelectBusinessPage.vue'),
      },
      {
        path: 'collection/farm',
        name: 'collection.farm',
        component: () => import('pages/Collection/FarmSelectPage.vue'),
      },
      {
        path: 'collection/:farmId/period',
        name: 'collection.period',
        component: () => import('pages/Collection/PeriodSelectPage.vue'),
      },
      {
        path: 'collection/:farmId/period/:periodId',
        name: 'collection.home',
        component: () => import('pages/Collection/CollectionPage.vue'),
      },
      {
        path: 'collection/:farmId/period/:periodId/report/:reportId',
        name: 'collection.report',
        props: route => ({ reportId: route.params.reportId }),
        component: () => import('src/pages/SupersetEmbedPage.vue'),
      },
      {
        path: 'collection/:farmId/period/:periodId/report',
        name: 'collection.reports',
        component: () => import('src/pages/SupersetIndexPage.vue'),
      },
      {
        path: 'collection/:farmId/period/:periodId/files',
        name: 'collection.files',
        component: () => import('pages/Collection/FileUploadPage.vue'),
      },
      {
        path: 'collection/:farmId/period/:periodId/paddocks',
        name: 'collection.paddocks',
        component: () => import('pages/Collection/PaddocksPage.vue'),
      },
      {
        path: 'collection/:farmId/period/:periodId/rain-gauges/:selectedGaugeId(\\d+)?/:selectedYear(\\d+)?/:selectedMonth(\\d+)?',
        name: 'collection.rain-gauges',
        props: route => ({ selectedGaugeId: route.params.selectedGaugeId, selectedYear: route.params.selectedYear, selectedMonth: route.params.selectedMonth }),
        component: () => import('pages/Collection/RaingaugePage.vue'),
      },
      {
        path: 'collection/:farmId/period/:periodId/livestock',
        component: () => import('layouts/LivestockLayout.vue'),
        children: livestockRoutes,
      },
      {
        path: 'collection/:farmId/period/:periodId/cropping',
        component: () => import('layouts/CroppingLayout.vue'),
        children: croppingRoutes,
      },
    ],
  },
  {
    path: '/',
    component: () => import('layouts/LoginLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'auth.login',
        component: () => import('src/pages/LoginPage.vue'),
      },
      {
        path: 'reset-password',
        name: 'auth.resetPassword',
        component: () => import('src/pages/PasswordResetPage.vue'),
      },
    ],
  },
]

export default routes
