import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import ValuationCategory from './ValuationCategory'

export default class ValuationUsage extends Model<{
  name: string
},
DefaultPersistedAttributes,
{
  valuation_categories: Array<ValuationCategory>
} > {
  public $resource (): string {
    return 'valuation_usages'
  }

  public valuation_categories (): HasMany<ValuationCategory> {
    return new HasMany(ValuationCategory, this)
  }
}
