import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsToMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany'
import FileFileTag from './FileFileTag'

export default class FileTag extends Model<{
  id: number
  created_at: string
  updated_at: string
  name: string
  color: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'file_tags'
  }

  public files (): BelongsToMany<FileTag> {
    return new BelongsToMany(FileTag, this)
  }
}
