/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/PaddockPriorPaddock.json'

import Paddock from './Paddock'

export default class PaddockPriorPaddock extends Model {
  static entity = 'paddock_prior_paddocks'

  static primaryKey = ['parent_paddock_id', 'prior_paddock_id']

  entity = 'paddock_prior_paddocks'

  static fields () {
    return {
      id: this.number(null),
      parent_paddock_id: this.number(null),
      prior_paddock_id: this.number(null),
      is_primary: this.boolean(null),

      parent_paddock: this.belongsTo(Paddock, 'parent_paddock_id'),
      prior_paddock: this.belongsTo(Paddock, 'prior_paddock_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare parent_paddock_id: number
  declare prior_paddock_id: number
  declare is_primary: string

  declare parent_paddock: Paddock
  declare prior_paddock: Paddock
}
