import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import EmployeeBill from './EmployeeBill'

export default class WorkCategory extends Model<{
  id: number
  created_at: string
  updated_at: string
  title: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'work_categories'
  }

  public employee_bills (): HasMany<EmployeeBill> {
    return new HasMany(EmployeeBill, this)
  }
}
