import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { MorphMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/morphMany'
import { BelongsToMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import PhoneNumber from './PhoneNumber'
import Address from './Address'
import DetailsUser from './DetailsUser'
import Farm from './Farm'
import BusinessUser from './BusinessUser'
import Business from './Business'
import Opportunity from './Opportunity'
import EmailAddress from './EmailAddress'
import Project from './Project'
import Role from './Role'
import QueableJob from './QueableJob'

export default class User extends Model<{
  id: number
  admin: string
  is_impersonating: string
  name: string
  email: string
  email_verified_at: string
  remember_token: string
  created_at: string
  updated_at: string
  agreed_to_terms: string
  deleted_at: string
  active: string
  color: string
  password: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'users'
  }

  public phone_numbers (): MorphMany<PhoneNumber> {
    return new MorphMany(PhoneNumber, this)
  }

  public addresses (): MorphMany<Address> {
    return new MorphMany(Address, this)
  }

  public farms (): BelongsToMany<Farm> {
    return new BelongsToMany(Farm, this)
  }

  public businesses (): BelongsToMany<Business> {
    return new BelongsToMany(Business, this)
  }

  public associated_businesses (): BelongsToMany<Business> {
    return new BelongsToMany(Business, this)
  }

  public managed_businesses (): HasMany<Business> {
    return new HasMany(Business, this)
  }

  public opportunities (): HasMany<Opportunity> {
    return new HasMany(Opportunity, this)
  }

  public emails (): MorphMany<EmailAddress> {
    return new MorphMany(EmailAddress, this)
  }

  public projects (): HasMany<Project> {
    return new HasMany(Project, this)
  }

  public roles (): HasMany<Role> {
    return new HasMany(Role, this)
  }

  public queable_jobs (): HasMany<QueableJob> {
    return new HasMany(QueableJob, this)
  }
}
