/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/CropTag.json'

import CropCollectionCropTag from './CropCollectionCropTag'
import CropCollection from './CropCollection'

export default class CropTag extends Model {
  static entity = 'crop_tags'

  static primaryKey = 'id'

  entity = 'crop_tags'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      name: this.string(null),
      color: this.string(null),

      crop_collections: this.belongsToMany(CropCollection, CropCollectionCropTag, 'crop_tag_id', 'crop_collection_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare name: string
  declare color: string

  declare crop_collections: CropCollection[]
}
