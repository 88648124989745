/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/CropCollectionStatus.json'

import CropCollectionEnterprise from './CropCollectionEnterprise'
import CropCollection from './CropCollection'

export default class CropCollectionStatus extends Model {
  static entity = 'crop_collection_statuses'

  static primaryKey = 'id'

  entity = 'crop_collection_statuses'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      name: this.string(null),

      enterprises: this.hasMany(CropCollectionEnterprise, 'status_id'),
      crop_collections: this.hasMany(CropCollection, 'status_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare name: string

  declare enterprises: CropCollectionEnterprise[]
  declare crop_collections: CropCollection[]
}
