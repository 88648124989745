import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'

export default class ExistingPlantItem extends Model<{
  id: number
  created_at: string
  updated_at: string
  farm_id: number
  year: string
  plant: string
  replacement_price: number
  plant_category_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'existing_plant_items'
  }
}
