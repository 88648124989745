import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import CropCollectionEnterprise from './CropCollectionEnterprise'
import CropCollection from './CropCollection'

export default class CropCollectionStatus extends Model<{
  id: number
  created_at: string
  updated_at: string
  name: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'crop_collection_statuses'
  }

  public enterprises (): HasMany<CropCollectionEnterprise> {
    return new HasMany(CropCollectionEnterprise, this)
  }

  public crop_collections (): HasMany<CropCollection> {
    return new HasMany(CropCollection, this)
  }
}
