/* eslint-disable camelcase */

import { Model } from '../types'

import AssetType from './AssetType'
import ValuationUsage from './ValuationUsage'
import ValuationCollSheetMap from './ValuationCollSheetMap'

export default class ValuationCategory extends Model {
  static entity = 'valuation_categories'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(null),
      asset_type_id: this.number(null),
      valuation_usage_id: this.number(null),
      valuation_coll_sheet_map_id: this.number(null),

      asset: this.belongsTo(AssetType, 'asset_type_id'),
      valuation_usage: this.belongsTo(ValuationUsage, 'valuation_usage_id'),
      valuation_coll_sheet_map: this.belongsTo(ValuationCollSheetMap, 'valuation_coll_sheet_map_id'),
    }
  }

  declare id: number
  declare name: string
  declare asset_type_id: number
  declare valuation_usage_id: number
  declare valuation_coll_sheet_map_id: number

  declare asset: AssetType
  declare valuation_usage: ValuationUsage
  declare valuation_coll_sheet_map: ValuationCollSheetMap
}
