/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/CropTypeFarmPeriod.json'

import CropType from './CropType'
import Farm from './Farm'
import Period from './Period'

export default class CropTypeFarmPeriod extends Model {
  static entity = 'crop_type_farm_periods'

  static primaryKey = 'id'

  entity = 'crop_type_farm_periods'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      crop_type_id: this.number(null),
      farm_id: this.number(null),
      period_id: this.number(null),
      all_questions_answered: this.boolean(null),

      crop_type: this.belongsTo(CropType, 'crop_type_id'),
      farm: this.belongsTo(Farm, 'farm_id'),
      period: this.belongsTo(Period, 'period_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare crop_type_id: number
  declare farm_id: number
  declare period_id: number
  declare all_questions_answered: string

  declare crop_type: CropType
  declare farm: Farm
  declare period: Period
}
