/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/Geolocation.json'

export default class Geolocation extends Model {
  static entity = 'geolocations'

  static primaryKey = 'id'

  entity = 'geolocations'

  static fields () {
    return {
      name: this.string(null),
      location: this.string(null),

    }
  }

  declare name: string
  declare location: string
}
