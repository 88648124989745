import { CropCollectionFallowType as Model } from '../models'
import { orionCropCollectionFallowType as Api } from '..'
import { repoFactory } from '../repositories/GenericRepository'
import { useRepo } from 'pinia-orm'

export function useCropCollectionFallowTypeRepo () {
  return useRepo(repoFactory(Model, Api))
}

export function fetchCropCollectionFallowType () {
  return useCropCollectionFallowTypeRepo().fetch()
}
