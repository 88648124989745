/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/RainGauge.json'

import Rainfall from './Rainfall'

export default class RainGauge extends Model {
  static entity = 'rain_gauges'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.attr(null),
      name: this.string(null),
      farm_id: this.number(null),

      rainfalls: this.hasMany(Rainfall, 'rain_gauge_id'),
    }
  }

  declare id: number
  declare name: string
  declare farm_id: number

  declare rainfalls: Rainfall[]
}
