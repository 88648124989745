/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/FertiliserProduct.json'

import ChemicalElementFertiliserProduct from './ChemicalElementFertiliserProduct'
import ChemicalElement from './ChemicalElement'
import FertiliserCollectionEvent from './FertiliserCollectionEvent'

export default class FertiliserProduct extends Model {
  static entity = 'fertiliser_products'

  static primaryKey = 'id'

  entity = 'fertiliser_products'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(null),

      chemical_elements: this.belongsToMany(ChemicalElement, ChemicalElementFertiliserProduct, 'fertiliser_product_id', 'chemical_element_id'),
      fertiliser_collection_events: this.hasMany(FertiliserCollectionEvent, 'fertiliser_product_id', 'id'),
    }
  }

  declare id: number
  declare name: string

  declare chemical_elements: ChemicalElement[]
  declare fertiliser_collection_events: FertiliserCollectionEvent[]
}
