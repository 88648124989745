import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '',
    name: 'collection.livestock',
    components: {
      default: () => import('pages/Collection/Livestock/LivestockSummaryPage.vue'),
    },
  },
  {
    path: 'enterprises',
    name: 'collection.livestock.enterprises',
    components: {
      default: () => import('pages/Collection/Livestock/LivestockEnterprisePage.vue'),
    },
  },
  {
    path: 'wizard/:livestockEnterpriseId(\\d+)?',
    name: 'collection.livestock.wizard',
    props: route => {
      if (typeof route.params.livestockEnterpriseId === 'string') {
        return { livestockEnterpriseId: Number.parseInt(route.params.livestockEnterpriseId) }
      }
    },
    component: () => import('pages/Collection/Livestock/StepperWizardPage.vue'),
  },
]

export default routes
