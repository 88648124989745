/* eslint-disable camelcase */

import { Model } from '../../types'

import CropCollection from './CropCollection'
import CropTerminationType from './CropTerminationType'
import { Temporal } from 'temporal-polyfill'
import { DateCast } from '../../casts'

export default class CustomTerminationEvent extends Model {
  static entity = 'custom_termination_events'

  static primaryKey = 'id'

  entity = 'custom_termination_events'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      crop_collection_id: this.number(null),
      crop_termination_type_id: this.number(null),
      date: this.attr(null),
      notes: this.attr(null),
      farm_id: this.number(null),
      period_id: this.number(null),

      crop_collection: this.belongsTo(CropCollection, 'crop_collection_id'),
      crop_termination_type: this.belongsTo(CropTerminationType, 'crop_termination_type_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare crop_collection_id: number
  declare crop_termination_type_id: number
  declare date: string
  declare notes: string

  declare crop_collection: CropCollection
  declare crop_termination_type: CropTerminationType
}
