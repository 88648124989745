import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import Farm from './Farm'

export default class LivestockAgistmentCollection extends Model<{
  id: number
  created_at: string
  updated_at: string
  farm_id: number
  year: string
  species: string
  class_of_stock: string
  number_in: number
  average_kg_per_head_on_arrival: number
  date_in: string
  number_out: number
  average_kg_per_head_on_departure: number
  date_out: string
  notes: string
  period_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'livestock_agistment_collections'
  }

  public farm (): BelongsTo<Farm> {
    return new BelongsTo(Farm, this)
  }
}
