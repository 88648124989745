/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/PhoneNumber.json'

export default class PhoneNumber extends Model {
  static entity = 'phone_numbers'

  static primaryKey = 'id'

  entity = 'phone_numbers'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      type: this.string(null),
      number: this.string(null),
      phone_numberable_type: this.string(null),
      phone_numberable_id: this.number(null),
      is_primary: this.boolean(null),

    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare type: string
  declare number: string
  declare phone_numberable_type: string
  declare phone_numberable_id: number
  declare is_primary: string
}
