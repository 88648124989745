/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/NewPlantPurchase.json'

export default class NewPlantPurchase extends Model {
  static entity = 'new_plant_purchases'

  static primaryKey = 'id'

  entity = 'new_plant_purchases'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      farm_id: this.number(null),
      year: this.string(null),
      plant: this.string(null),
      date_purchased: this.attr(null),
      purchase_price: this.number(null),
      plant_category_id: this.number(null),
      period_id: this.number(null),

    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare farm_id: number
  declare year: string
  declare plant: string
  declare date_purchased: string
  declare purchase_price: number
  declare plant_category_id: number
  declare period_id: number
}
