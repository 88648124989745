/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/LivestockPurpose.json'

import LivestockSpeciesType from './LivestockSpeciesType'

export default class LivestockPurpose extends Model {
  static entity = 'livestock_purposes'

  static primaryKey = 'id'

  entity = 'livestock_purposes'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      name: this.string(null),
      livestock_species_type_id: this.number(null),

      species_type: this.belongsTo(LivestockSpeciesType, 'livestock_species_type_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare name: string
  declare livestock_species_type_id: number

  declare species_type: LivestockSpeciesType
}
