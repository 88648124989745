import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import { MorphTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/morphTo'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import User from './User'
import Project from './Project'
import EmployeeBill from './EmployeeBill'

export default class Bill extends Model<{
  id: number
  created_at: string
  updated_at: string
  date_invoiced: string
  description: string
  status: string
  billable_type: string
  billable_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'bills'
  }

  public employee (): BelongsTo<User> {
    return new BelongsTo(User, this)
  }

  public employee_bills (): HasMany<EmployeeBill> {
    return new HasMany(EmployeeBill, this)
  }
}
