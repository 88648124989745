import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import { BelongsToMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import { MorphMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/morphMany'
import User from './User'
import BusinessUser from './BusinessUser'
import BusinessFarm from './BusinessFarm'
import Farm from './Farm'
import Project from './Project'
import Address from './Address'

export default class Business extends Model<{
  id: number
  name: string
  billing_name: string
  billing_email: string
  billing_user_id: number
  billing_address_id: number
  business_address_id: number
  manager_id: number
  farms_string: string
  users_string: string
  notes: string
  billing_notes: string
  docs: Record<string, unknown>
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'businesses'
  }

  public billing_user (): BelongsTo<User> {
    return new BelongsTo(User, this)
  }

  public manager (): BelongsTo<User> {
    return new BelongsTo(User, this)
  }

  public users (): BelongsToMany<User> {
    return new BelongsToMany(User, this)
  }

  public farms (): BelongsToMany<Farm> {
    return new BelongsToMany(Farm, this)
  }

  public projects (): HasMany<Project> {
    return new HasMany(Project, this)
  }

  public addresses (): MorphMany<Address> {
    return new MorphMany(Address, this)
  }
}
