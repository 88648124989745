import { defineStore } from 'pinia'
import { useFarmRepo, usePeriodRepo, useFarmPeriodRepo, useCropCollectionFallowTypeRepo, useCropCollectionStatusRepo } from '@agripath/orm'
import { Temporal } from 'temporal-polyfill'
import { useFetchPeriodData } from 'src/composables/useFetchPeriodData'
import LogRocket from 'logrocket'

export const useAppStore = defineStore('app', () => {
  const farmRepo = useFarmRepo()
  const periodRepo = usePeriodRepo()
  const farmPeriodRepo = useFarmPeriodRepo()
  const fallowTypeRepo = useCropCollectionFallowTypeRepo()
  const statusRepo = useCropCollectionStatusRepo()

  const dataLoaded = ref(false)

  const currentFarmId = ref<number>()
  const currentPeriodId = ref<number>()

  const farm = computed(() => {
    return currentFarmId.value ? farmRepo.find(currentFarmId.value) : undefined
  })

  const period = computed(() => {
    return currentPeriodId.value ? periodRepo.find(currentPeriodId.value) : undefined
  })

  const farmPeriods = computed(() => {
    return farmPeriodRepo.query().where('farm_id', currentFarmId.value).with('period').orderBy('period.start_date').get().sort((a, b) => Temporal.ZonedDateTime.compare(a.period.start_date, b.period.start_date))
  })

  const priorPeriod = computed(() => {
    const currIndex = farmPeriods.value.findIndex(f => f.period_id === currentPeriodId.value)
    return farmPeriods.value[currIndex - 1]?.period ?? undefined
  })

  const nextPeriod = computed(() => {
    const currIndex = farmPeriods.value.findIndex(f => f.period_id === currentPeriodId.value)
    return farmPeriods.value[currIndex + 1]?.period ?? undefined
  })

  const soilTypes = computed(() => {
    return [
      {
        label: 'Heavy',
        value: 'Heavy',
      },
      {
        label: 'Heavy to Medium',
        value: 'Heavy to Medium',
      },
      {
        label: 'Medium',
        value: 'Medium',
      },
      {
        label: 'Medium to Light',
        value: 'Medium to Light',
      },
      {
        label: 'Light',
        value: 'Light',
      },
    ]
  })

  const irrigationTypes = computed(() => {
    return [
      {
        label: 'Pivot',
        value: 'Pivot',
      },
      {
        label: 'Lateral',
        value: 'Lateral',
      },
      {
        label: 'Syphon',
        value: 'Syphon',
      },
      {
        label: 'Bankless',
        value: 'Bankless',
      },
      {
        label: 'Subsurface drip',
        value: 'Subsurface drip',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ]
  })

  const fallowTypeOptions = computed(() => (fallowTypeRepo.orderBy('name').all().map(m => { return { value: m.id, label: m.name } })))

  const statusOptions = computed(() => (statusRepo.orderBy('name').all().map(m => { return { value: m.id, label: m.name } })))

  const showLeftDrawer = ref(true)

  const ui = ref({
    filterHeight: 0,
  })

  interface ExtApp {
    id: number,
    label: string,
    slug: string,
    needs_farm_id: boolean,
    needs_period_id: boolean,
    icon: string,
    color: string,
    default_app_version : {
      version: string
    }
  }

  const apps = ref<ExtApp[]>([])

  watch(currentPeriodId, async (newVal, oldVal) => {
    if ((oldVal !== newVal) && newVal) {
      await useFetchPeriodData().fetch()
      if (import.meta.env.VITE_APP_ENABLE_LOGROCKET && import.meta.env.VITE_APP_LOGROCKET_ID) {
        await nextTick()
        LogRocket.track('SelectPeriod', {
          id: period.value.id,
          name: period.value.name,
        })
      }
    }
  })

  watch(currentFarmId, async (newVal, oldVal) => {
    if ((oldVal !== newVal) && newVal) {
      await useFetchPeriodData().fetch()
      if (import.meta.env.VITE_APP_ENABLE_LOGROCKET && import.meta.env.VITE_APP_LOGROCKET_ID) {
        await nextTick()
        LogRocket.track('SelectFarm', {
          id: farm.value?.id,
          name: farm.value?.farm_name,
        })
      }
    }
  })

  return {
    currentFarmId,
    currentPeriodId,
    farm,
    period,
    priorPeriod,
    nextPeriod,
    farmPeriods,
    soilTypes,
    irrigationTypes,
    showLeftDrawer,
    dataLoaded,
    fallowTypeOptions,
    statusOptions,
    ui,
    apps,
  }
})
