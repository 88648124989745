import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import Farm from './Farm'

export default class FarmProperty extends Model<{
  name: string
  color: string
  colour_id: number
  farm_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'farm_properties'
  }

  public farm (): BelongsTo<Farm> {
    return new BelongsTo(Farm, this)
  }
}
