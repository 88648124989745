import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsToMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany'
import ProductProject from './ProductProject'
import Project from './Project'

export default class Product extends Model<{
  id: number
  title: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'products'
  }

  public projects (): BelongsToMany<Project> {
    return new BelongsToMany(Project, this)
  }
}
