/* eslint-disable camelcase */

import { Model } from '../types'

import ValuationCategory from './ValuationCategory'

export default class ValuationCollSheetMap extends Model {
  static entity = 'valuation_coll_sheet_maps'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(null),

      valuation_categories: this.hasMany(ValuationCategory, 'valuation_coll_sheet_map_id'),
    }
  }

  declare id: number
  declare name: string

  declare valuation_categories: Array<ValuationCategory>
}
