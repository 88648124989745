import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsToMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import ChemicalElementFertiliserProduct from './ChemicalElementFertiliserProduct'
import ChemicalElement from './ChemicalElement'
import FertiliserCollectionEvent from './FertiliserCollectionEvent'

export default class FertiliserProduct extends Model<{
  id: number
  name: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'fertiliser_products'
  }

  public chemical_elements (): BelongsToMany<ChemicalElement> {
    return new BelongsToMany(ChemicalElement, this)
  }

  public fertiliser_collection_events (): HasMany<FertiliserCollectionEvent> {
    return new HasMany(FertiliserCollectionEvent, this)
  }
}
