import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import LivestockSpeciesType from './LivestockSpeciesType'

export default class LivestockClass extends Model<{
  id: number
  created_at: string
  updated_at: string
  name: string
  species_type_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'livestock_classes'
  }

  public species_type (): BelongsTo<LivestockSpeciesType> {
    return new BelongsTo(LivestockSpeciesType, this)
  }
}
