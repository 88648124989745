import { Model } from '@tailflow/laravel-orion/lib/model'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import CropType from './CropType'

export default class CropCategory extends Model<{
  id: number
  name: string
  color: string
},
Record<string, never> > {
  public $resource (): string {
    return 'crop_categories'
  }

  public crop_types (): HasMany<CropType> {
    return new HasMany(CropType, this)
  }
}
