/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/Expense.json'

import User from './User'
import Project from './Project'
import ExpenseCategory from './ExpenseCategory'

export default class Expense extends Model {
  static entity = 'expenses'

  static primaryKey = 'id'

  entity = 'expenses'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      date: this.attr(null),
      amount: this.number(null),
      description: this.string(null),
      employee_id: this.number(null),
      expensable_type: this.string(null),
      expensable_id: this.number(null),
      expense_category_id: this.number(null),

      employee: this.belongsTo(User, 'employee_id'),
      expensable: this.morphTo([Project], 'expensable_id', 'expensable_type'),
      expense_category: this.belongsTo(ExpenseCategory, 'expense_category_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare date: string
  declare amount: number
  declare description: string
  declare employee_id: number
  declare expensable_type: string
  declare expensable_id: number
  declare expense_category_id: number

  declare employee: User
  declare expensable: unknown
  declare expense_category: ExpenseCategory
}
