/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/LivestockSpeciesType.json'

import LivestockClass from './LivestockClass'

export default class LivestockSpeciesType extends Model {
  static entity = 'livestock_species_types'

  static primaryKey = 'id'

  entity = 'livestock_species_types'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      name: this.string(null),

      livestock_classes: this.hasMany(LivestockClass, 'species_type_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare name: string

  declare livestock_classes: LivestockClass[]
}
