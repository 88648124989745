import { Period as Model } from '../models'
import { orionPeriod as Api } from '..'
import { repoFactory } from '../repositories/GenericRepository'
import { useRepo } from 'pinia-orm'

export function usePeriodRepo () {
  return useRepo(repoFactory(Model, Api))
}

export function fetchPeriod () {
  return usePeriodRepo().fetch()
}
