/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/BusinessUser.json'

export default class BusinessUser extends Model {
  static entity = 'business_user'

  static primaryKey = ['business_id', 'user_id']

  entity = 'business_user'

  static fields () {
    return {
      id: this.number(null),
      business_id: this.number(null),
      user_id: this.number(null),

    }
  }

  declare id: number
  declare business_id: number
  declare user_id: number
}
