/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/LivestockFeedCollection.json'

import Farm from './Farm'

export default class LivestockFeedCollection extends Model {
  static entity = 'livestock_feed_collections'

  static apiEntity = 'livestock_feeds'

  static primaryKey = 'id'

  entity = 'livestock_feed_collections'

  apiEntity = 'livestock_feeds'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      farm_id: this.number(null),
      year: this.string(null),
      type: this.string(null),
      opening: this.number(null),
      opening_value: this.number(null),
      purchased_or_produced_ammount: this.number(null),
      purchased_value: this.number(null),
      fed_amount: this.number(null),
      fed_value: this.number(null),
      sold_amount: this.number(null),
      sold_value: this.number(null),
      closing_amount: this.number(null),
      closing_value: this.number(null),
      total_opening_value: this.number(null),
      total_value_purchased_or_produced_amount: this.number(null),
      total_value_fed: this.number(null),
      total_value_sold: this.number(null),
      total_closing_value: this.number(null),
      period_id: this.number(null),

      farm: this.belongsTo(Farm, 'farm_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare farm_id: number
  declare year: string
  declare type: string
  declare opening: number
  declare opening_value: number
  declare purchased_or_produced_ammount: number
  declare purchased_value: number
  declare fed_amount: number
  declare fed_value: number
  declare sold_amount: number
  declare sold_value: number
  declare closing_amount: number
  declare closing_value: number
  declare total_opening_value: number
  declare total_value_purchased_or_produced_amount: number
  declare total_value_fed: number
  declare total_value_sold: number
  declare total_closing_value: number
  declare period_id: number

  declare farm: Farm
}
