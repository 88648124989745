/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/DrylandCrop.json'

import Farm from './Farm'

export default class DrylandCrop extends Model {
  static entity = 'dryland_crops'

  static primaryKey = 'id'

  entity = 'dryland_crops'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      period_id: this.number(null),
      farm_id: this.number(null),
      Zone: this.string(null),
      Crop: this.string(null),
      Year: this.string(null),
      Avg_Sowing: this.number(null),
      Avg_Harvest: this.number(null),
      Area: this.number(null),
      Grain_Harvested_Area: this.number(null),
      Hay_Harvested_Area: this.number(null),
      Grain_Produced: this.number(null),
      Grain_Yield: this.number(null),
      ML: this.number(null),
      ML_ha: this.number(null),
      Grain_Price: this.number(null),
      Grain_Price_Onfarm: this.number(null),
      Grain_Sold_T: this.number(null),
      Hay_Produced: this.number(null),
      Hay_Yield: this.number(null),
      Hay_Price: this.number(null),
      Grain_IncomeRecv: this.number(null),
      Grain_IncomeDue: this.number(null),
      Grain_RiskMgmt: this.number(null),
      Grain_Used_T: this.number(null),
      Grain_Used_Value: this.number(null),
      Hay_IncomeRecv: this.number(null),
      Hay_IncomeDue: this.number(null),
      TradingProfit: this.number(null),
      Grain_Opening_Value: this.number(null),
      Grain_Opening_T: this.number(null),
      Grain_Closing_Value: this.number(null),
      Grain_Closing_T: this.number(null),
      Hay_Opening_Value: this.number(null),
      Hay_Opening_T: this.number(null),
      Hay_Closing_Value: this.number(null),
      Hay_Closing_T: this.number(null),
      Hay_Sold_T: this.number(null),
      Grain_Change_In_Inventory_Value: this.number(null),
      Grain_Change_In_Inventory_T: this.number(null),
      Hay_Change_In_Inventory_Value: this.number(null),
      Hay_Change_In_Inventory_T: this.number(null),
      ChangeInventory: this.number(null),
      Grain_Feed_Value: this.number(null),
      Grain_Feed_T: this.number(null),
      Hay_Used_Value: this.number(null),
      Hay_Used_T: this.number(null),
      Hay_Feed_Value: this.number(null),
      Hay_Feed_T: this.number(null),
      Grain_Hay_Feed_Value: this.number(null),
      Grain_Purchases_Value: this.number(null),
      Grain_Purchases_T: this.number(null),
      Hay_Purchases_Value: this.number(null),
      Hay_Purchases_T: this.number(null),
      Crop_Grazed_Value: this.number(null),
      Purchases: this.number(null),
      GrossIncome: this.number(null),
      Exp_Seed: this.number(null),
      Exp_ChemicalFallow: this.number(null),
      Exp_ChemicalCrop: this.number(null),
      Exp_ContractingFallow: this.number(null),
      Exp_ContractingSpraying: this.number(null),
      Exp_ContractingFarming: this.number(null),
      Exp_ContractingHarvesting: this.number(null),
      Exp_FertiliserN: this.number(null),
      Exp_FertiliserP: this.number(null),
      Exp_FertiliserOther: this.number(null),
      Exp_WaterPumping: this.number(null),
      Exp_WaterExpenses: this.number(null),
      Exp_AnimalHealth: this.number(null),
      Exp_LivestockContractServices: this.number(null),
      Exp_LivestockFeedAgistment: this.number(null),
      Exp_CasualLabour: this.number(null),
      Exp_FreightCartage: this.number(null),
      Exp_FuelLube: this.number(null),
      Exp_Insurance: this.number(null),
      Exp_RMPlant: this.number(null),
      Exp_RMIrrigation: this.number(null),
      Exp_RMStructures: this.number(null),
      Exp_SellingCost: this.number(null),
      Exp_Ginning: this.number(null),
      Exp_GMOLicense: this.number(null),
      Exp_Packaging: this.number(null),
      Exp_Sundry: this.number(null),
      Exp_Wages: this.number(null),
      Exp_Depreciation: this.number(null),
      Expense_Total: this.number(null),
      Expense_Total_T: this.number(null),
      GrossMargin: this.number(null),
      GrossMargin_T: this.number(null),
      GrossMargin_ML: this.number(null),
      TPMLCosts: this.number(null),
      AreaCosts: this.number(null),
      YieldCosts: this.number(null),
      WaterCosts: this.number(null),
      Mlperha: this.number(null),
      StartingMoisture: this.number(null),
      IncropRainfall: this.number(null),
      PAW: this.number(null),
      WUE: this.number(null),
      Prior_Area_Cereal: this.number(null),
      Prior_Area_Legume: this.number(null),
      Prior_Area_Oilseed: this.number(null),
      Prior_Area_Other: this.number(null),
      Prior_T_Cereal: this.number(null),
      Prior_T_Legume: this.number(null),
      Prior_T_Oilseed: this.number(null),
      Prior_T_Other: this.number(null),
      Prior_Yield_Cereal: this.number(null),
      Prior_Yield_Legume: this.number(null),
      Prior_Yield_Oilseed: this.number(null),
      Prior_Yield_Other: this.number(null),
      Fertiliser_N: this.number(null),
      Fertiliser_P: this.number(null),
      Labour_Months: this.number(null),
      Plant_Used: this.number(null),
      WaraDC: this.number(null),
      WaraGM: this.number(null),
      WaraGI: this.number(null),
      Zone2: this.number(null),
      Discounts: this.number(null),
      Inc_Selling_costs: this.number(null),
      Insurance_Tonnes: this.number(null),
      Insurance_Payout: this.number(null),
      OtherIncome: this.number(null),
      HayDiscounts: this.number(null),
      Other_IncomeHay: this.number(null),
      Hay_Selling_Costs: this.number(null),
      Hay_Insurance_Tonnes: this.number(null),
      Hay_Insurance_Payout: this.number(null),
      Exp_ChemicalOther1: this.number(null),
      Exp_ChemicalOther2: this.number(null),
      Exp_ContractingOther: this.number(null),
      Exp_FertiliserK: this.number(null),
      Exp_FertiliserOther2: this.number(null),
      Exp_FertiliserOther3: this.number(null),
      Exp_WaterTempPurchases: this.number(null),
      Exp_RMLivestock: this.number(null),
      Exp_Processing: this.number(null),
      Exp_Agronomy: this.number(null),
      Exp_Sundry2: this.number(null),
      Exp_Sundry3: this.number(null),

      farm: this.belongsTo(Farm, 'farm_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare period_id: number
  declare farm_id: number
  declare Zone: string
  declare Crop: string
  declare Year: string
  declare Avg_Sowing: number
  declare Avg_Harvest: number
  declare Area: number
  declare Grain_Harvested_Area: number
  declare Hay_Harvested_Area: number
  declare Grain_Produced: number
  declare Grain_Yield: number
  declare ML: number
  declare ML_ha: number
  declare Grain_Price: number
  declare Grain_Price_Onfarm: number
  declare Grain_Sold_T: number
  declare Hay_Produced: number
  declare Hay_Yield: number
  declare Hay_Price: number
  declare Grain_IncomeRecv: number
  declare Grain_IncomeDue: number
  declare Grain_RiskMgmt: number
  declare Grain_Used_T: number
  declare Grain_Used_Value: number
  declare Hay_IncomeRecv: number
  declare Hay_IncomeDue: number
  declare TradingProfit: number
  declare Grain_Opening_Value: number
  declare Grain_Opening_T: number
  declare Grain_Closing_Value: number
  declare Grain_Closing_T: number
  declare Hay_Opening_Value: number
  declare Hay_Opening_T: number
  declare Hay_Closing_Value: number
  declare Hay_Closing_T: number
  declare Hay_Sold_T: number
  declare Grain_Change_In_Inventory_Value: number
  declare Grain_Change_In_Inventory_T: number
  declare Hay_Change_In_Inventory_Value: number
  declare Hay_Change_In_Inventory_T: number
  declare ChangeInventory: number
  declare Grain_Feed_Value: number
  declare Grain_Feed_T: number
  declare Hay_Used_Value: number
  declare Hay_Used_T: number
  declare Hay_Feed_Value: number
  declare Hay_Feed_T: number
  declare Grain_Hay_Feed_Value: number
  declare Grain_Purchases_Value: number
  declare Grain_Purchases_T: number
  declare Hay_Purchases_Value: number
  declare Hay_Purchases_T: number
  declare Crop_Grazed_Value: number
  declare Purchases: number
  declare GrossIncome: number
  declare Exp_Seed: number
  declare Exp_ChemicalFallow: number
  declare Exp_ChemicalCrop: number
  declare Exp_ContractingFallow: number
  declare Exp_ContractingSpraying: number
  declare Exp_ContractingFarming: number
  declare Exp_ContractingHarvesting: number
  declare Exp_FertiliserN: number
  declare Exp_FertiliserP: number
  declare Exp_FertiliserOther: number
  declare Exp_WaterPumping: number
  declare Exp_WaterExpenses: number
  declare Exp_AnimalHealth: number
  declare Exp_LivestockContractServices: number
  declare Exp_LivestockFeedAgistment: number
  declare Exp_CasualLabour: number
  declare Exp_FreightCartage: number
  declare Exp_FuelLube: number
  declare Exp_Insurance: number
  declare Exp_RMPlant: number
  declare Exp_RMIrrigation: number
  declare Exp_RMStructures: number
  declare Exp_SellingCost: number
  declare Exp_Ginning: number
  declare Exp_GMOLicense: number
  declare Exp_Packaging: number
  declare Exp_Sundry: number
  declare Exp_Wages: number
  declare Exp_Depreciation: number
  declare Expense_Total: number
  declare Expense_Total_T: number
  declare GrossMargin: number
  declare GrossMargin_T: number
  declare GrossMargin_ML: number
  declare TPMLCosts: number
  declare AreaCosts: number
  declare YieldCosts: number
  declare WaterCosts: number
  declare Mlperha: number
  declare StartingMoisture: number
  declare IncropRainfall: number
  declare PAW: number
  declare WUE: number
  declare Prior_Area_Cereal: number
  declare Prior_Area_Legume: number
  declare Prior_Area_Oilseed: number
  declare Prior_Area_Other: number
  declare Prior_T_Cereal: number
  declare Prior_T_Legume: number
  declare Prior_T_Oilseed: number
  declare Prior_T_Other: number
  declare Prior_Yield_Cereal: number
  declare Prior_Yield_Legume: number
  declare Prior_Yield_Oilseed: number
  declare Prior_Yield_Other: number
  declare Fertiliser_N: number
  declare Fertiliser_P: number
  declare Labour_Months: number
  declare Plant_Used: number
  declare WaraDC: number
  declare WaraGM: number
  declare WaraGI: number
  declare Zone2: number
  declare Discounts: number
  declare Inc_Selling_costs: number
  declare Insurance_Tonnes: number
  declare Insurance_Payout: number
  declare OtherIncome: number
  declare HayDiscounts: number
  declare Other_IncomeHay: number
  declare Hay_Selling_Costs: number
  declare Hay_Insurance_Tonnes: number
  declare Hay_Insurance_Payout: number
  declare Exp_ChemicalOther1: number
  declare Exp_ChemicalOther2: number
  declare Exp_ContractingOther: number
  declare Exp_FertiliserK: number
  declare Exp_FertiliserOther2: number
  declare Exp_FertiliserOther3: number
  declare Exp_WaterTempPurchases: number
  declare Exp_RMLivestock: number
  declare Exp_Processing: number
  declare Exp_Agronomy: number
  declare Exp_Sundry2: number
  declare Exp_Sundry3: number

  declare farm: Farm
}
