/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/LivestockAgistedAwayCollection.json'

export default class LivestockAgistedAwayCollection extends Model {
  static entity = 'livestock_agisted_away_collections'

  static apiEntity = 'livestock_agisted_aways'

  static primaryKey = 'id'

  entity = 'livestock_agisted_away_collections'

  apiEntity = 'livestock_agisted_aways'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      class_of_stock: this.string(null),
      number_away: this.number(null),
      away_kg: this.number(null),
      date_away: this.attr(null),
      number_back: this.number(null),
      return_kg: this.number(null),
      date_back: this.attr(null),
      enterprise: this.string(null),
      farm_id: this.number(null),
      year: this.number(null),
      notes: this.string(null),
      period_id: this.number(null),
      enterprise_id: this.number(null),

    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare class_of_stock: string
  declare number_away: number
  declare away_kg: number
  declare date_away: string
  declare number_back: number
  declare return_kg: number
  declare date_back: string
  declare enterprise: string
  declare farm_id: number
  declare year: number
  declare notes: string
  declare period_id: number
  declare enterprise_id: number
}
