import { defineStore } from 'pinia'
import { useAppStore } from 'src/stores/appStore'
import { CropCollection, useCropCollectionRepo } from '@agripath/orm'
import { DeclassifyPiniaOrmModels } from 'pinia-orm-helpers'

export const useCropCollectionStore = defineStore('cropCollection', () => {
  const app = useAppStore()
  const repo = useCropCollectionRepo()

  const { currentPeriodId, currentFarmId } = storeToRefs(app)

  const fetchCropCollections = async () => {
    if (currentPeriodId.value) {
      await repo.fetch({ periodIds: [currentPeriodId.value] })
    }
  }

  const fetch = async () => {
    await Promise.all([
      fetchCropCollections(),
    ])
  }

  const cropCollections = computed(() => {
    return repo
      .where('period_id', app.currentPeriodId)
      .where('farm_id', app.currentFarmId)
      .get()
  })

  const query = (withoutScope = false) => {
    const query = repo.query()
    if (withoutScope) {
      return query
    }
    return query
      .where('period_id', app.currentPeriodId)
      .where('farm_id', app.currentFarmId)
  }

  const make = (record?: DeclassifyPiniaOrmModels<CropCollection>) => {
    const farmPeriod = { period_id: app.currentPeriodId, farm_id: app.currentFarmId }
    return repo.make({ ...record, ...farmPeriod })
  }

  const persist = async<F extends DeclassifyPiniaOrmModels<CropCollection> | DeclassifyPiniaOrmModels<CropCollection>[]> (form: F, fields: string[] | null = null): Promise<F extends CropCollection ? CropCollection : CropCollection[]> => {
    return await repo.persist(form, fields)
  }

  return {
    fetch,
    cropCollections,
    persist,
    make,
    query,
  }
})
