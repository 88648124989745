/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/DetailsUser.json'

export default class DetailsUser extends Model {
  static entity = 'details_user'

  static primaryKey = ['details_id', 'user_id']

  entity = 'details_user'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      details_id: this.number(null),
      user_id: this.number(null),
      is_farms_manager: this.boolean(null),

    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare details_id: number
  declare user_id: number
  declare is_farms_manager: string
}
