import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import User from './User'
import Product from './Product'

export default class Opportunity extends Model<{
  id: number
  title: string
  employee_id: number
  product_id: number
  rating: number
  open_date: string
  estimated_close_date: string
  target_fees: number
  probability: number
  closed: string
  last_contact: string
  notes: string
  action_required: string
  user_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'opportunities'
  }

  public employee (): BelongsTo<User> {
    return new BelongsTo(User, this)
  }

  public product (): BelongsTo<Product> {
    return new BelongsTo(Product, this)
  }

  public user (): BelongsTo<User> {
    return new BelongsTo(User, this)
  }
}
