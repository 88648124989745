import { useSowingEventStore } from 'src/stores/sowingEventStore'
import { usePaddockStore } from 'src/stores/paddockStore'
import { useCropCollectionStore } from 'src/stores/cropCollectionStore'

export function useFetchPeriodData () {
  const sowingEventStore = useSowingEventStore()
  const paddockStore = usePaddockStore()
  const cropCollectionStore = useCropCollectionStore()

  const fetch = () => {
    sowingEventStore.fetch()
    paddockStore.fetch()
    cropCollectionStore.fetch()
  }

  return {
    fetch,
  }
}
