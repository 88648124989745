import { CropTypeFarmPeriod as Model } from '../models'
import { orionCropTypeFarmPeriod as Api } from '..'
import { repoFactory } from '../repositories/GenericRepository'
import { useRepo } from 'pinia-orm'

export function useCropTypeFarmPeriodRepo () {
  return useRepo(repoFactory(Model, Api))
}

export function fetchCropTypeFarmPeriod () {
  return useCropTypeFarmPeriodRepo().fetch()
}
