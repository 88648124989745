/* eslint-disable camelcase */

import { Model } from '../../types'

import CropCollection from './CropCollection'
import FertiliserProduct from './FertiliserProduct'
import FertiliserChemicalApplication from './FertiliserChemicalApplication'

import { Temporal } from 'temporal-polyfill'
import { DateCast } from '../../casts'

export default class FertiliserCollectionEvent extends Model {
  static entity = 'fertiliser_collection_events'

  static primaryKey = 'id'

  entity = 'fertiliser_collection_events'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      occurrence: this.number(null),
      crop_collection_id: this.number(null),
      application_date: this.attr(null),
      pre_applied: this.boolean(null),
      fertiliser_product_id: this.number(null),
      farm_id: this.number(null),
      period_id: this.number(null),

      crop_collection: this.belongsTo(CropCollection, 'crop_collection_id'),
      fertiliser_product: this.belongsTo(FertiliserProduct, 'fertiliser_product_id'),
      fertiliser_chemical_applications: this.hasMany(FertiliserChemicalApplication, 'fertiliser_collection_event_id', 'id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare occurrence: number
  declare crop_collection_id: number
  declare application_date: string
  declare pre_applied: string
  declare fertiliser_product_id: number
  declare farm_id: number
  declare period_id: number

  declare crop_collection: CropCollection
  declare fertiliser_product: FertiliserProduct
  declare fertiliser_chemical_applications: FertiliserChemicalApplication[]
}
