import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import { BelongsToMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import { MorphMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/morphMany'
import Geolocation from './Geolocation'
import DetailsUser from './DetailsUser'
import User from './User'
import Asset from './Asset'
import FarmProperty from './FarmProperty'
import DrylandCrop from './DrylandCrop'
import IrrigatedCrop from './IrrigatedCrop'
import ExtensiveLivestock from './ExtensiveLivestock'
import RainGauge from './RainGauge'
import Address from './Address'
import Paddock from './Paddock'
import LivestockAgistmentCollection from './LivestockAgistmentCollection'
import LivestockFeedCollection from './LivestockFeedCollection'
import LivestockAgistedAwayCollection from './LivestockAgistedAwayCollection'
import LivestockBreedingDetailsCollection from './LivestockBreedingDetailsCollection'
import LivestockInventoryCollection from './LivestockInventoryCollection'
import LivestockPurchasesCollection from './LivestockPurchasesCollection'
import LivestockSalesCollection from './LivestockSalesCollection'
import LivestockWoolCollection from './LivestockWoolCollection'
import ExistingPlantItem from './ExistingPlantItem'
import NewPlantPurchase from './NewPlantPurchase'
import Project from './Project'
import SoldPlantSale from './SoldPlantSale'
import File from './File'
import BusinessFarm from './BusinessFarm'
import Business from './Business'
import FarmPeriod from './FarmPeriod'
import Period from './Period'
import CropTypeFarmPeriod from './CropTypeFarmPeriod'
import CropType from './CropType'
import ProfitFocusGroup from './ProfitFocusGroup'
import FarmLivestockEnterprise from './FarmLivestockEnterprise'

export default class Farm extends Model<{
  id: number
  farm_name: string
  billing_notes: string
  manager_id: number
  profit_focus_group_id: number
  pivot: string
  address_id: number
  tagsString: string
  geolocation_id: string
},
{},
{
  periods: Period[],
  crop_type_farm_periods: CropTypeFarmPeriod[]
} > {
  public $resource (): string {
    return 'farms'
  }

  public geolocation (): BelongsTo<Geolocation> {
    return new BelongsTo(Geolocation, this)
  }

  public users (): BelongsToMany<User> {
    return new BelongsToMany(User, this)
  }

  public assets (): HasMany<Asset> {
    return new HasMany(Asset, this)
  }

  public farm_properties (): HasMany<FarmProperty> {
    return new HasMany(FarmProperty, this)
  }

  public dryland_crops (): HasMany<DrylandCrop> {
    return new HasMany(DrylandCrop, this)
  }

  public irrigated_crops (): HasMany<IrrigatedCrop> {
    return new HasMany(IrrigatedCrop, this)
  }

  public extensive_livestock (): HasMany<ExtensiveLivestock> {
    return new HasMany(ExtensiveLivestock, this)
  }

  public manager (): BelongsTo<User> {
    return new BelongsTo(User, this)
  }

  public rain_gauges (): HasMany<RainGauge> {
    return new HasMany(RainGauge, this)
  }

  public addresses (): MorphMany<Address> {
    return new MorphMany(Address, this)
  }

  public paddocks (): HasMany<Paddock> {
    return new HasMany(Paddock, this)
  }

  public livestock_agistment_collections (): HasMany<LivestockAgistmentCollection> {
    return new HasMany(LivestockAgistmentCollection, this)
  }

  public livestock_feed_collections (): HasMany<LivestockFeedCollection> {
    return new HasMany(LivestockFeedCollection, this)
  }

  public livestock_agisted_away_collections (): HasMany<LivestockAgistedAwayCollection> {
    return new HasMany(LivestockAgistedAwayCollection, this)
  }

  public livestock_breeding_details_collections (): HasMany<LivestockBreedingDetailsCollection> {
    return new HasMany(LivestockBreedingDetailsCollection, this)
  }

  public livestock_inventory_collections (): HasMany<LivestockInventoryCollection> {
    return new HasMany(LivestockInventoryCollection, this)
  }

  public livestock_purchases_collections (): HasMany<LivestockPurchasesCollection> {
    return new HasMany(LivestockPurchasesCollection, this)
  }

  public livestock_sales_collections (): HasMany<LivestockSalesCollection> {
    return new HasMany(LivestockSalesCollection, this)
  }

  public livestock_wool_collections (): HasMany<LivestockWoolCollection> {
    return new HasMany(LivestockWoolCollection, this)
  }

  public existing_plant_items (): HasMany<ExistingPlantItem> {
    return new HasMany(ExistingPlantItem, this)
  }

  public new_plant_purchases (): HasMany<NewPlantPurchase> {
    return new HasMany(NewPlantPurchase, this)
  }

  public projects (): HasMany<Project> {
    return new HasMany(Project, this)
  }

  public sold_plant_sales (): HasMany<SoldPlantSale> {
    return new HasMany(SoldPlantSale, this)
  }

  public files (): MorphMany<File> {
    return new MorphMany(File, this)
  }

  public businesses (): BelongsToMany<Business> {
    return new BelongsToMany(Business, this)
  }

  public periods (): BelongsToMany<Period> {
    return new BelongsToMany(Period, this)
  }

  public crop_types (): BelongsToMany<CropType> {
    return new BelongsToMany(CropType, this)
  }

  public crop_type_farm_periods (): HasMany<CropTypeFarmPeriod> {
    return new HasMany(CropTypeFarmPeriod, this)
  }

  public profit_focus_group (): BelongsTo<ProfitFocusGroup> {
    return new BelongsTo(ProfitFocusGroup, this)
  }

  public farm_livestock_enterprises (): HasMany<FarmLivestockEnterprise> {
    return new HasMany(FarmLivestockEnterprise, this)
  }
}
