import { PaddockPriorPaddock as Model } from '../models'
import { orionPaddockPriorPaddock as Api } from '..'
import { repoFactory } from '../repositories/GenericRepository'
import { useRepo } from 'pinia-orm'

export function usePaddockPriorPaddockRepo () {
  return useRepo(repoFactory(Model, Api))
}

export function fetchPaddockPriorPaddock () {
  return usePaddockPriorPaddockRepo().fetch()
}
