import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'

export default class LivestockInventoryCollection extends Model<{
  id: number
  created_at: string
  updated_at: string
  enterprise: string
  farm_id: number
  year: string
  class_of_stock: string
  opening_number: number
  opening_average_weight: number
  closing_number: number
  closing_average_weight: number
  notes: string
  period_id: number
  enterprise_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'livestock_inventory_collections'
  }
}
