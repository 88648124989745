/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/CropCollection.json'

import Paddock from './Paddock'
import CropType from './CropType'
import CropCollectionCropTag from './CropCollectionCropTag'
import CropTag from './CropTag'
import HayCollectionEvent from './HayCollectionEvent'
import GrazingCollectionEvent from './GrazingCollectionEvent'
import GrainCollectionEvent from './GrainCollectionEvent'
import LintCollectionEvent from './LintCollectionEvent'
import SowingCollectionEvent from './SowingCollectionEvent'
import IrrigationCollectionEvent from './IrrigationCollectionEvent'
import FertiliserCollectionEvent from './FertiliserCollectionEvent'
import FallowCollectionEvent from './FallowCollectionEvent'
import CropCollectionFallowType from './CropCollectionFallowType'
import CropCollectionStatus from './CropCollectionStatus'
import CropCollectionEnterprise from './CropCollectionEnterprise'
import Period from './Period'
import CustomTerminationEvent from './CustomTerminationEvent'

export default class CropCollection extends Model {
  static entity = 'crop_collections'

  static primaryKey = 'id'

  entity = 'crop_collections'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      period_id: this.number(null),
      termination_eventable_id: this.number(null),
      termination_eventable_type: this.string(null),
      farm_id: this.number(null),
      paddock_id: this.number(null),
      double_crop: this.boolean(null),
      current_crop_id: this.number(null),
      primary_prior_collection_id: this.number(null),
      fallow_type_id: this.number(null),
      status_id: this.number(null),
      enterprise_id: this.number(null),

      paddock: this.belongsTo(Paddock, 'paddock_id'),
      primary_prior_collection: this.belongsTo(CropCollection, 'primary_prior_collection_id'),
      primary_future_collection: this.hasOne(CropCollection, 'primary_prior_collection_id'),
      current_crop: this.belongsTo(CropType, 'current_crop_id'),
      tags: this.belongsToMany(CropTag, CropCollectionCropTag, 'crop_collection_id', 'crop_tag_id'),
      hay_collection_events: this.hasMany(HayCollectionEvent, 'crop_collection_id', 'id'),
      grazing_collection_events: this.hasMany(GrazingCollectionEvent, 'crop_collection_id', 'id'),
      grain_collection_events: this.hasMany(GrainCollectionEvent, 'crop_collection_id', 'id'),
      lint_collection_events: this.hasMany(LintCollectionEvent, 'crop_collection_id', 'id'),
      sowing_collection_events: this.hasMany(SowingCollectionEvent, 'crop_collection_id', 'id'),
      irrigation_collection_events: this.hasMany(IrrigationCollectionEvent, 'crop_collection_id', 'id'),
      fertiliser_collection_events: this.hasMany(FertiliserCollectionEvent, 'crop_collection_id', 'id'),
      fallow_collection_events: this.hasMany(FallowCollectionEvent, 'crop_collection_id', 'id'),
      fallow_type: this.belongsTo(CropCollectionFallowType, 'fallow_type_id'),
      status: this.belongsTo(CropCollectionStatus, 'status_id'),
      enterprise: this.belongsTo(CropCollectionEnterprise, 'enterprise_id'),
      period: this.belongsTo(Period, 'period_id'),
      termination_event: this.morphTo([GrainCollectionEvent, LintCollectionEvent, HayCollectionEvent, CustomTerminationEvent], 'termination_eventable_id', 'termination_eventable_type'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare period_id: number
  declare termination_eventable_id: number
  declare termination_eventable_type: string
  declare farm_id: number
  declare paddock_id: number
  declare double_crop: string
  declare current_crop_id: number
  declare primary_prior_collection_id: number | null
  declare fallow_type_id: number
  declare status_id: number
  declare enterprise_id: number

  declare paddock: Paddock
  declare primary_prior_collection: CropCollection
  declare primary_future_collection: CropCollection
  declare current_crop: CropType
  declare tags: CropTag[]
  declare hay_collection_events: HayCollectionEvent[]
  declare grazing_collection_events: GrazingCollectionEvent[]
  declare grain_collection_events: GrainCollectionEvent[]
  declare lint_collection_events: LintCollectionEvent[]
  declare sowing_collection_events: SowingCollectionEvent[]
  declare irrigation_collection_events: IrrigationCollectionEvent[]
  declare fertiliser_collection_events: FertiliserCollectionEvent[]
  declare fallow_collection_events: FallowCollectionEvent[]
  declare fallow_type: CropCollectionFallowType
  declare status: CropCollectionStatus
  declare enterprise: CropCollectionEnterprise
  declare period: Period
  declare termination_event: GrainCollectionEvent | LintCollectionEvent | HayCollectionEvent | CustomTerminationEvent | null
}
