import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '',
    name: 'collection.cropping',
    components: {
      default: () => import('pages/Collection/Cropping/CroppingSummaryPage.vue'),
    },
  },
  {
    path: 'wizard/:cropTypeId(\\d+)?',
    name: 'collection.cropping.wizard',
    props: route => {
      if (typeof route.params.cropTypeId === 'string') {
        return { cropTypeId: Number.parseInt(route.params.cropTypeId) }
      }
    },
    component: () => import('pages/Collection/Cropping/StepperWizardPage.vue'),
  },
  {
    path: 'spreadsheet',
    name: 'collection.spreadsheet',
    components: {
      default: () => import('pages/Collection/Cropping/SpreadsheetPage.vue'),
    },
  },
]

export default routes
