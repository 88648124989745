import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import FertiliserCollectionEvent from './FertiliserCollectionEvent'
import ChemicalElement from './ChemicalElement'

export default class FertiliserChemicalApplication extends Model<{
  fertiliser_collection_event_id: number
  chemical_element_id: number
  unit_ha: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'fertiliser_chemical_applications'
  }

  public fertiliser_collection_event (): BelongsTo<FertiliserCollectionEvent> {
    return new BelongsTo(FertiliserCollectionEvent, this)
  }

  public chemical_element (): BelongsTo<ChemicalElement> {
    return new BelongsTo(ChemicalElement, this)
  }
}
