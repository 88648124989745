import { Business as Model } from '../models'
import { orionBusiness as Api } from '..'
import { repoFactory } from '../repositories/GenericRepository'
import { useRepo } from 'pinia-orm'

export function useBusinessRepo (pinia?) {
  return useRepo(repoFactory(Model, Api), pinia)
}

export function fetchBusiness (pinia?) {
  return useBusinessRepo(pinia).fetch()
}
