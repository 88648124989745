import { defineStore } from 'pinia'
import { isAxiosError } from 'axios'
import { useAxios, negativeNotification } from '@agripath/common-vue-components'
import * as LogRocket from 'logrocket'
import {
  useBusinessRepo,
  useFarmRepo,
  useFarmPeriodRepo,
  useCropTypeFarmPeriodRepo,
  orionFarm,
  fetchRainGauge,
  fetchFarmProperty,
  Orion,
} from '@agripath/orm'
import { useGlobalSettings } from 'src/stores/globalSettings'
import { LocalStorage } from 'quasar'
import { useAppStore } from './appStore'

export interface UserResponse {
  id: number | string
  admin: boolean
  name: string
  email: string
  agreed_to_terms: boolean
  active: boolean
  farm_focus: boolean
  newsome_agriculture: boolean
  color: string
  is_impersonating: boolean
  clickup_token: string | null
  associated_businesses: []
  managed_businesses: []
  impersonating?: UserResponse
}

export interface LoginResponse {
  two_factor: boolean
}

const endpoints = {
  login: '/api/login',
  fetch: '/api/auth/me',
  authenticated: '/auth/authenticated',
  logout: '/api/logout',
  takeImpersonate: '/api/impersonate/take/',
  leaveImpersonate: '/api/impersonate/leave',
  tokenLogin: '/api/auth/create-token',
  tokenLogout: '/api/auth/revoke-token',
  forgotPassword: '/api/password-reset/create',
  resetPassword: '/api/password-reset/reset',
}

const axiosFetch = useAxios<UserResponse>()
const axiosLogin = useAxios<LoginResponse>()
const axiosLogout = useAxios()
const axiosTokenLogin = useAxios<string>()
const axiosTokenLogout = useAxios()
const axiosPasswordReset = useAxios()

export type AuthStore = ReturnType<typeof useAuthStore>

export const useAuthStore = defineStore('auth', () => {
  const pinia = getActivePinia()
  const businessRepo = useBusinessRepo(pinia)
  const farmRepo = useFarmRepo(pinia)
  const farmPeriodRepo = useFarmPeriodRepo(pinia)
  const cropTypeFarmPeriodRepo = useCropTypeFarmPeriodRepo(pinia)

  const impersonatingId = ref<number>()

  const token = ref<string>()
  const user = ref<UserResponse | undefined>()
  const currentBusinessId = ref<number | undefined>(undefined)
  const isImpersonating = computed(() => !!impersonatingId.value)
  const agreedToTerms = computed(() => !!user.value?.agreed_to_terms)

  const isAdmin = computed(() => !!user?.value?.admin)
  const notAdmin = computed(() => !isAdmin.value)
  const loggedIn = computed(() => !!user?.value)
  const notLoggedIn = computed(() => !loggedIn.value)

  const app = useAppStore()

  const businesses = computed(() => {
    return businessRepo.all()
  })

  const impersonating = computed(() => {
    if (isImpersonating.value) {
      return user.value?.impersonating
    }
  })

  const currentBusiness = computed(() => {
    if (currentBusinessId.value) {
      const businessRepo = useBusinessRepo()
      return businessRepo.find(currentBusinessId.value)
    } else {
      return undefined
    }
  })

  const fetchFarms = async () => {
    // Clear the old farm data
    farmRepo.flush()
    fetchRainGauge()
    fetchFarmProperty()
    const fetchedFarms = await orionFarm.$query().with(['periods', 'crop_type_farm_periods']).get(0)
    farmRepo.insert(fetchedFarms.map(m => m.$attributes))
    farmPeriodRepo.insert(fetchedFarms.flatMap(m => m.$relations.periods.map(m => m.$attributes.pivot)))
    cropTypeFarmPeriodRepo.insert(fetchedFarms.flatMap(m => m.$relations.crop_type_farm_periods.map(m => m.$attributes)))
  }

  const refreshFarmPeriods = async () => {
    const fetchedFarms = await orionFarm.$query().with(['periods']).get(0)
    farmPeriodRepo.save(fetchedFarms.flatMap(m => m.$relations.periods.map(m => m.$attributes.pivot)))
  }

  const setBusinessId = async (businessId?: number) => {
    app.currentFarmId = undefined
    localStorage.removeItem('farmId')
    app.currentPeriodId = undefined
    fetchFarms()
    useGlobalSettings().preCollectionFetched = false
    LocalStorage.set('currentBusinessId', businessId)
    currentBusinessId.value = businessId
    if (import.meta.env.VITE_APP_ENABLE_LOGROCKET && import.meta.env.VITE_APP_LOGROCKET_ID) {
      await nextTick()
      LogRocket.track('SelectBusiness', {
        id: currentBusiness.value.id,
        name: currentBusiness.value.name,
      })
    }
  }

  async function fetch () {
    try {
      businessRepo.flush()
      await axiosFetch.get(endpoints.fetch)
      if (!axiosFetch.error.value) {
        user.value = axiosFetch.data.value ?? undefined
        if (user.value) {
          if (isImpersonating && user.value.impersonating) {
            if (user.value.impersonating.associated_businesses) { businessRepo.save(user.value.impersonating.associated_businesses) }

            if (user.value.impersonating.managed_businesses) { businessRepo.save(user.value.impersonating.managed_businesses) }

            if (businesses.value.length === 1) {
              await setBusinessId(businesses.value[0].id)
            }
          } else {
            if (user.value.associated_businesses) { businessRepo.save(user.value.associated_businesses) }

            if (user.value.managed_businesses) { businessRepo.save(user.value.managed_businesses) }

            if (businesses.value.length === 1) {
              await setBusinessId(businesses.value[0].id)
            }
          }
        }
      }
    } catch (error) {
      if (isAxiosError(error)) {
        switch (error.response?.status) {
          case 422:
            return false

          default:
            return false
        }
      } else {
        return false
      }
    }
  }

  async function impersonate (id?: number) {
    try {
      if (id) {
        localStorage.setItem('impersonatingId', id.toString())
        impersonatingId.value = id
        await fetch()
        if (import.meta.env.VITE_APP_ENABLE_LOGROCKET && import.meta.env.VITE_APP_LOGROCKET_ID) {
          await nextTick()
          LogRocket.track('StartImpersonating', {
            name: user.value?.impersonating?.name,
            email: user.value?.impersonating?.email,
          })
        }
      } else {
        localStorage.removeItem('impersonatingId')
        impersonatingId.value = undefined
        farmRepo.flush()
        businessRepo.flush()
        app.currentFarmId = undefined
        localStorage.removeItem('farmId')
        app.currentPeriodId = undefined
        LocalStorage.remove('currentBusinessId')
        currentBusinessId.value = undefined
        await fetch()
        if (import.meta.env.VITE_APP_ENABLE_LOGROCKET && import.meta.env.VITE_APP_LOGROCKET_ID) {
          LogRocket.track('StopImpersonating')
        }
      }
    } catch (error) {
      // window.location.reload()
    }
  }

  async function login (credentials: { email: string; password: string }) {
    try {
      token.value = undefined
      localStorage.removeItem('token')
      await axiosTokenLogin.post(endpoints.tokenLogin, credentials)
      if (axiosTokenLogin.data.value) {
        token.value = axiosTokenLogin.data.value
        localStorage.setItem('token', token.value)
        Orion.setToken(token.value)
        await fetch()
        if (import.meta.env.VITE_APP_ENABLE_LOGROCKET && import.meta.env.VITE_APP_LOGROCKET_ID && user.value) {
          LogRocket.identify(user.value.id, {
            name: user.value.name,
            email: user.value.email,
          })
        }
        return true
      } else {
        return false
      }
    } catch (error) {
      if (isAxiosError(error)) {
        switch (error.response?.status) {
          case 422:
            return false

          default:
            return false
        }
      } else {
        return false
      }
    }
  }

  async function logout () {
    await axiosTokenLogout.post(endpoints.tokenLogout)
    token.value = undefined
    localStorage.removeItem('token')
    user.value = undefined
    const farmRepo = useFarmRepo()
    farmRepo.flush()
    businessRepo.flush()
    LocalStorage.remove('currentBusinessId')
    currentBusinessId.value = undefined
    impersonate()
    return axiosLogout.data.value
  }

  async function forgotPassword (email: string) {
    await axiosPasswordReset.post(endpoints.forgotPassword, { email })
    if (axiosPasswordReset.error.value) {
      return Promise.reject(axiosPasswordReset.error.value)
    }
  }

  async function resetPassword (form: { email: string, password: string, password_confirmation: string, token: string }) {
    await axiosPasswordReset.post(endpoints.resetPassword, form)
    if (axiosPasswordReset.error.value) {
      return Promise.reject(axiosPasswordReset.error.value)
    }
  }

  return {
    currentBusinessId,
    currentBusiness,
    businesses,
    setBusinessId,
    fetchFarms,
    refreshFarmPeriods,
    user,
    impersonating,
    isImpersonating,
    agreedToTerms,
    isAdmin,
    notAdmin,
    loggedIn,
    notLoggedIn,
    token,
    impersonatingId,

    login,
    fetch,
    logout,
    impersonate,
    forgotPassword,
    resetPassword,

    fetching: axiosFetch.loading,
    fetchError: axiosFetch.error,

    loggingOut: axiosLogout.loading,
    logoutError: axiosLogout.error,

    loggingIn: axiosLogin.loading,
    loginError: axiosLogin.error,

  }
})
