/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/EmployeeBill.json'

import WorkCategory from './WorkCategory'
import Bill from './Bill'
import User from './User'

export default class EmployeeBill extends Model {
  static entity = 'employee_bills'

  static primaryKey = 'id'

  entity = 'employee_bills'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      bill_id: this.number(null),
      employee_id: this.number(null),
      work_category_id: this.number(null),
      amount: this.number(null),

      work_category: this.belongsTo(WorkCategory, 'work_category_id'),
      bill: this.belongsTo(Bill, 'bill_id'),
      employee: this.belongsTo(User, 'employee_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare bill_id: number
  declare employee_id: number
  declare work_category_id: number
  declare amount: number

  declare work_category: WorkCategory
  declare bill: Bill
  declare employee: User
}
