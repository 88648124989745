/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/FileTag.json'

import FileFileTag from './FileFileTag'

export default class FileTag extends Model {
  static entity = 'file_tags'

  static primaryKey = 'id'

  entity = 'file_tags'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      name: this.string(null),
      color: this.string(null),

      files: this.belongsToMany(FileTag, FileFileTag, 'file_tag_id', 'file_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare name: string
  declare color: string

  declare files: FileTag[]
}
