import { SowingCollectionEvent as Model } from '../models'
import { orionSowingCollectionEvent as Api } from '..'
import { repoFactory } from '../repositories/GenericRepository'
import { useRepo } from 'pinia-orm'

export function useSowingCollectionEventRepo () {
  return useRepo(repoFactory(Model, Api))
}

export function fetchSowingCollectionEvent () {
  return useSowingCollectionEventRepo().fetch()
}
