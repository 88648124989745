import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'

export default class EmailAddress extends Model<{
  id: number
  created_at: string
  updated_at: string
  type: string
  email: string
  email_addressable_type: string
  email_addressable_id: number
  is_primary: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'email_addresses'
  }
}
