import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import Farm from './Farm'

export default class LivestockFeedCollection extends Model<{
  id: number
  created_at: string
  updated_at: string
  farm_id: number
  year: string
  type: string
  opening: number
  opening_value: number
  purchased_or_produced_ammount: number
  purchased_value: number
  fed_amount: number
  fed_value: number
  sold_amount: number
  sold_value: number
  closing_amount: number
  closing_value: number
  total_opening_value: number
  total_value_purchased_or_produced_amount: number
  total_value_fed: number
  total_value_sold: number
  total_closing_value: number
  period_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'livestock_feed_collections'
  }

  public farm (): BelongsTo<Farm> {
    return new BelongsTo(Farm, this)
  }
}
