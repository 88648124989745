/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/IrrigationCollectionEvent.json'

import CropCollection from './CropCollection'

export default class IrrigationCollectionEvent extends Model {
  static entity = 'irrigation_collection_events'

  static primaryKey = 'id'

  entity = 'irrigation_collection_events'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      irrigation_date: this.attr(null),
      total_ml_applied: this.number(null),
      occurrence: this.number(null),
      crop_collection_id: this.number(null),
      farm_id: this.number(null),
      period_id: this.number(null),

      crop_collection: this.belongsTo(CropCollection, 'crop_collection_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare irrigation_date: string
  declare total_ml_applied: number
  declare occurrence: number
  declare crop_collection_id: number
  declare farm_id: number
  declare period_id: number

  declare crop_collection: CropCollection
}
