/* eslint-disable camelcase */

import { Model } from '../../types'

import LivestockEnterprise from './LivestockEnterprise'
import Farm from './Farm'

export default class FarmLivestockEnterprise extends Model {
  static entity = 'farm_livestock_enterprises'

  static primaryKey = 'id'

  entity = 'farm_livestock_enterprises'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      livestock_enterprise_id: this.number(null),
      farm_id: this.number(null),

      livestock_enterprise: this.belongsTo(LivestockEnterprise, 'livestock_enterprise_id'),
      farm: this.belongsTo(Farm, 'farm_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare livestock_enterprise_id: number
  declare farm_id: number

  declare livestock_enterprise: LivestockEnterprise
  declare farm: Farm
}
