import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'

export default class AssetType extends Model<{
  id: number
  created_at: string
  updated_at: string
  name: string
  color: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'asset_types'
  }
}
