import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import { HasOne } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasOne'
import { BelongsToMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import Paddock from './Paddock'
import CropType from './CropType'
import CropCollectionCropTag from './CropCollectionCropTag'
import CropTag from './CropTag'
import HayCollectionEvent from './HayCollectionEvent'
import GrazingCollectionEvent from './GrazingCollectionEvent'
import GrainCollectionEvent from './GrainCollectionEvent'
import LintCollectionEvent from './LintCollectionEvent'
import SowingCollectionEvent from './SowingCollectionEvent'
import IrrigationCollectionEvent from './IrrigationCollectionEvent'
import FertiliserCollectionEvent from './FertiliserCollectionEvent'
import FallowCollectionEvent from './FallowCollectionEvent'
import CropCollectionFallowType from './CropCollectionFallowType'
import CropCollectionStatus from './CropCollectionStatus'
import CropCollectionEnterprise from './CropCollectionEnterprise'
import Period from './Period'
import CropCollectionTerminationEventDate from './CropCollectionTerminationEventDate'

export default class CropCollection extends Model<{
  period_id: number
  termination_eventable_id: number
  termination_eventable_type: string
  farm_id: number
  paddock_id: number
  double_crop: string
  current_crop_id: number
  primary_prior_collection_id: number | null
  fallow_type_id: number
  status_id: number
  enterprise_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'crop_collections'
  }

  public paddock (): BelongsTo<Paddock> {
    return new BelongsTo(Paddock, this)
  }

  public primary_prior_collection (): BelongsTo<CropCollection> {
    return new BelongsTo(CropCollection, this)
  }

  public primary_future_collection (): HasOne<CropCollection> {
    return new HasOne(CropCollection, this)
  }

  public current_crop (): BelongsTo<CropType> {
    return new BelongsTo(CropType, this)
  }

  public tags (): BelongsToMany<CropTag> {
    return new BelongsToMany(CropTag, this)
  }

  public hay_collection_events (): HasMany<HayCollectionEvent> {
    return new HasMany(HayCollectionEvent, this)
  }

  public grazing_collection_events (): HasMany<GrazingCollectionEvent> {
    return new HasMany(GrazingCollectionEvent, this)
  }

  public grain_collection_events (): HasMany<GrainCollectionEvent> {
    return new HasMany(GrainCollectionEvent, this)
  }

  public lint_collection_events (): HasMany<LintCollectionEvent> {
    return new HasMany(LintCollectionEvent, this)
  }

  public sowing_collection_events (): HasMany<SowingCollectionEvent> {
    return new HasMany(SowingCollectionEvent, this)
  }

  public irrigation_collection_events (): HasMany<IrrigationCollectionEvent> {
    return new HasMany(IrrigationCollectionEvent, this)
  }

  public fertiliser_collection_events (): HasMany<FertiliserCollectionEvent> {
    return new HasMany(FertiliserCollectionEvent, this)
  }

  public fallow_collection_events (): HasMany<FallowCollectionEvent> {
    return new HasMany(FallowCollectionEvent, this)
  }

  public fallow_type (): BelongsTo<CropCollectionFallowType> {
    return new BelongsTo(CropCollectionFallowType, this)
  }

  public status (): BelongsTo<CropCollectionStatus> {
    return new BelongsTo(CropCollectionStatus, this)
  }

  public enterprise (): BelongsTo<CropCollectionEnterprise> {
    return new BelongsTo(CropCollectionEnterprise, this)
  }

  public period (): BelongsTo<Period> {
    return new BelongsTo(Period, this)
  }
}
