/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/FarmPeriod.json'

import Farm from './Farm'
import Period from './Period'

export default class FarmPeriod extends Model {
  static entity = 'farm_period'

  static primaryKey = ['id']

  entity = 'farm_period'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      status: this.string(null),
      farm_id: this.number(null),
      period_id: this.number(null),

      farm: this.belongsTo(Farm, 'farm_id'),
      period: this.belongsTo(Period, 'period_id'),
    }
  }

  get startDate () {
    return this.period.start_date
  }

  get endDate () {
    return this.period.end_date
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare status: string
  declare farm_id: number
  declare period_id: number

  declare farm: Farm
  declare period: Period
}
