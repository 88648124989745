/* eslint-disable camelcase */

import { Model } from '../../types'

import Farm from './Farm'
import CropCollection from './CropCollection'
import AssetType from './AssetType'
import Period from './Period'
import PaddockPriorPaddock from './PaddockPriorPaddock'
import RainGauge from './RainGauge'
import FarmProperty from './FarmProperty'

import ValuationCategory from '../ValuationCategory'
export default class Paddock extends Model {
  static entity = 'paddocks'

  static primaryKey = 'id'

  entity = 'paddocks'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      farm_id: this.number(null),
      name: this.string(null),
      area: this.number(null),
      asset_type_id: this.number(null),
      rain_gauge_id: this.attr(null),
      property_id: this.number(null),
      soil_type: this.string(null),
      irrigation_type: this.string(null),
      sharefarm_percentage: this.number(null),
      primary_parent_paddock_id: this.number(null),
      period_id: this.number(null),
      fallow_efficiency: this.number(null),
      ancestorIds: this.attr([]),
      water_holding: this.number(null),
      valuation_category_id: this.number(null),

      farm: this.belongsTo(Farm, 'farm_id'),
      crop_collections: this.hasMany(CropCollection, 'paddock_id'),
      parent_paddock: this.belongsTo(Paddock, 'primary_parent_paddock_id'),
      asset_type: this.belongsTo(AssetType, 'asset_type_id'),
      period: this.belongsTo(Period, 'period_id'),
      prior_paddocks: this.belongsToMany(Paddock, PaddockPriorPaddock, 'parent_paddock_id', 'prior_paddock_id'),
      parent_paddocks: this.belongsToMany(Paddock, PaddockPriorPaddock, 'prior_paddock_id', 'parent_paddock_id'),
      rain_gauge: this.belongsTo(RainGauge, 'rain_gauge_id'),
      property: this.belongsTo(FarmProperty, 'property_id'),
      valuation_category: this.belongsTo(ValuationCategory, 'valuation_category_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare farm_id: number
  declare name: string
  declare area: number
  declare asset_type_id: number
  declare rain_gauge_id: string
  declare property_id: number
  declare soil_type: string
  declare irrigation_type: string
  declare sharefarm_percentage: number
  declare primary_parent_paddock_id: number
  declare period_id: number
  declare fallow_efficiency: number
  declare ancestorIds: []
  declare water_holding: number
  declare valuation_category_id: number

  declare farm: Farm
  declare crop_collections: CropCollection[]
  declare parent_paddock: Paddock
  declare asset_type: AssetType
  declare period: Period
  declare prior_paddocks: Paddock[]
  declare parent_paddocks: Paddock[]
  declare rain_gauge: RainGauge
  declare property: FarmProperty
  declare valuation_category: ValuationCategory
}
