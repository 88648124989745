/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/ProductProject.json'

export default class ProductProject extends Model {
  static entity = 'product_projects'

  static primaryKey = ['product_id', 'project_id']

  entity = 'product_projects'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      product_id: this.number(null),
      project_id: this.number(null),

    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare product_id: number
  declare project_id: number
}
