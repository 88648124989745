import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import Period from './Period'
import Farm from './Farm'

export default class Asset extends Model<{
  id: number
  created_at: string
  updated_at: string
  period_id: number
  farm_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'assets'
  }

  public periods (): BelongsTo<Period> {
    return new BelongsTo(Period, this)
  }

  public farms (): BelongsTo<Farm> {
    return new BelongsTo(Farm, this)
  }
}
