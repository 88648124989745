/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/LivestockWoolCollection.json'

export default class LivestockWoolCollection extends Model {
  static entity = 'livestock_wool_collections'

  static apiEntity = 'livestock_wools'

  static primaryKey = 'id'

  entity = 'livestock_wool_collections'

  apiEntity = 'livestock_wools'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      farm_id: this.number(null),
      year: this.string(null),
      enterprise: this.string(null),
      class_of_stock: this.string(null),
      number: this.number(null),
      month_shorn: this.number(null),
      wool_cut: this.number(null),
      gross_wool: this.number(null),
      micron: this.number(null),
      yield_percentage: this.number(null),
      strength: this.number(null),
      vm_percentage: this.number(null),
      length: this.number(null),
      notes: this.string(null),
      period_id: this.number(null),
      months_since_last_sheering: this.number(null),
      enterprise_id: this.number(null),

    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare farm_id: number
  declare year: string
  declare enterprise: string
  declare class_of_stock: string
  declare number: number
  declare month_shorn: number
  declare wool_cut: number
  declare gross_wool: number
  declare micron: number
  declare yield_percentage: number
  declare strength: number
  declare vm_percentage: number
  declare length: number
  declare notes: string
  declare period_id: number
  declare months_since_last_sheering: number
  declare enterprise_id: number
}
