import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import Farm from './Farm'

export default class IrrigatedCrop extends Model<{
  id: number
  created_at: string
  updated_at: string
  period_id: number
  farm_id: number
  Zone: string
  Crop: string
  Year: string
  Avg_Sowing: number
  Avg_Harvest: number
  Area: number
  Grain_Harvested_Area: number
  Hay_Harvested_Area: number
  Grain_Produced: number
  Grain_Yield: number
  ML: number
  ML_ha: number
  Grain_Price: number
  Grain_Price_Onfarm: number
  Grain_Sold_T: number
  Hay_Produced: number
  Hay_Yield: number
  Hay_Price: number
  Grain_IncomeRecv: number
  Grain_IncomeDue: number
  Grain_RiskMgmt: number
  Grain_Used_T: number
  Grain_Used_Value: number
  Hay_IncomeRecv: number
  Hay_IncomeDue: number
  TradingProfit: number
  Grain_Opening_Value: number
  Grain_Opening_T: number
  Grain_Closing_Value: number
  Grain_Closing_T: number
  Hay_Opening_Value: number
  Hay_Opening_T: number
  Hay_Closing_Value: number
  Hay_Closing_T: number
  Hay_Sold_T: number
  Grain_Change_In_Inventory_Value: number
  Grain_Change_In_Inventory_T: number
  Hay_Change_In_Inventory_Value: number
  Hay_Change_In_Inventory_T: number
  ChangeInventory: number
  Grain_Feed_Value: number
  Grain_Feed_T: number
  Hay_Used_Value: number
  Hay_Used_T: number
  Hay_Feed_Value: number
  Hay_Feed_T: number
  Grain_Hay_Feed_Value: number
  Grain_Purchases_Value: number
  Grain_Purchases_T: number
  Hay_Purchases_Value: number
  Hay_Purchases_T: number
  Crop_Grazed_Value: number
  Purchases: number
  GrossIncome: number
  Exp_Seed: number
  Exp_ChemicalFallow: number
  Exp_ChemicalCrop: number
  Exp_ContractingFallow: number
  Exp_ContractingSpraying: number
  Exp_ContractingFarming: number
  Exp_ContractingHarvesting: number
  Exp_FertiliserN: number
  Exp_FertiliserP: number
  Exp_FertiliserOther: number
  Exp_WaterPumping: number
  Exp_WaterExpenses: number
  Exp_AnimalHealth: number
  Exp_LivestockContractServices: number
  Exp_LivestockFeedAgistment: number
  Exp_CasualLabour: number
  Exp_FreightCartage: number
  Exp_FuelLube: number
  Exp_Insurance: number
  Exp_RMPlant: number
  Exp_RMIrrigation: number
  Exp_RMStructures: number
  Exp_SellingCost: number
  Exp_Ginning: number
  Exp_GMOLicense: number
  Exp_Packaging: number
  Exp_Sundry: number
  Exp_Wages: number
  Exp_Depreciation: number
  Expense_Total: number
  Expense_Total_T: number
  GrossMargin: number
  GrossMargin_T: number
  GrossMargin_ML: number
  TPMLCosts: number
  AreaCosts: number
  YieldCosts: number
  WaterCosts: number
  Mlperha: number
  StartingMoisture: number
  IncropRainfall: number
  PAW: number
  WUE: number
  Prior_Area_Cereal: number
  Prior_Area_Legume: number
  Prior_Area_Oilseed: number
  Prior_Area_Other: number
  Prior_T_Cereal: number
  Prior_T_Legume: number
  Prior_T_Oilseed: number
  Prior_T_Other: number
  Prior_Yield_Cereal: number
  Prior_Yield_Legume: number
  Prior_Yield_Oilseed: number
  Prior_Yield_Other: number
  Fertiliser_N: number
  Fertiliser_P: number
  Labour_Months: number
  Plant_Used: number
  WaraDC: number
  WaraGM: number
  WaraGI: number
  Zone2: number
  Discounts: number
  Inc_Selling_costs: number
  Insurance_Tonnes: number
  Insurance_Payout: number
  OtherIncome: number
  HayDiscounts: number
  Other_IncomeHay: number
  Hay_Selling_Costs: number
  Hay_Insurance_Tonnes: number
  Hay_Insurance_Payout: number
  Exp_ChemicalOther1: number
  Exp_ChemicalOther2: number
  Exp_ContractingOther: number
  Exp_FertiliserK: number
  Exp_FertiliserOther2: number
  Exp_FertiliserOther3: number
  Exp_WaterTempPurchases: number
  Exp_RMLivestock: number
  Exp_Processing: number
  Exp_Agronomy: number
  Exp_Sundry2: number
  Exp_Sundry3: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'irrigated_crops'
  }

  public farm (): BelongsTo<Farm> {
    return new BelongsTo(Farm, this)
  }
}
