/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/LivestockBreedingDetailsCollection.json'

export default class LivestockBreedingDetailsCollection extends Model {
  static entity = 'livestock_breeding_details_collections'

  static apiEntity = 'livestock_breeding_details'

  static primaryKey = 'id'

  entity = 'livestock_breeding_details_collections'

  apiEntity = 'livestock_breeding_details'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      enterprise: this.string(null),
      farm_id: this.number(null),
      year: this.string(null),
      date_joined: this.attr(null),
      number_joined: this.number(null),
      number_of_sires: this.number(null),
      length_of_joining: this.number(null),
      number_born: this.number(null),
      date_weaned: this.attr(null),
      number: this.number(null),
      weaning_weight: this.number(null),
      weaning_percentage: this.number(null),
      notes: this.string(null),
      period_id: this.number(null),
      enterprise_id: this.number(null),

    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare enterprise: string
  declare farm_id: number
  declare year: string
  declare date_joined: string
  declare number_joined: number
  declare number_of_sires: number
  declare length_of_joining: number
  declare number_born: number
  declare date_weaned: string
  declare number: number
  declare weaning_weight: number
  declare weaning_percentage: number
  declare notes: string
  declare period_id: number
  declare enterprise_id: number
}
