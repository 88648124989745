/* eslint-disable camelcase */

import { Model } from '../../types'

import LivestockSpeciesType from './LivestockSpeciesType'

export default class LivestockClass extends Model {
  static entity = 'livestock_classes'

  static primaryKey = 'id'

  entity = 'livestock_classes'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      name: this.string(null),
      species_type_id: this.number(null),

      species_type: this.belongsTo(LivestockSpeciesType, 'species_type_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare name: string
  declare species_type_id: number

  declare species_type: LivestockSpeciesType
}
