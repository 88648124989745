/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/Role.json'

import User from './User'

export default class Role extends Model {
  static entity = 'roles'

  static primaryKey = ['role', 'user_id']

  entity = 'roles'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      role: this.string(null),
      user_id: this.number(null),

      users: this.belongsTo(User, 'user_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare role: string
  declare user_id: number

  declare users: User
}
