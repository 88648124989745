import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import User from './User'

export default class QueableJob extends Model<{
  id: number
  created_at: string
  updated_at: string
  selected: string
  description: string
  status: string
  error_message: string
  user_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'queable_jobs'
  }

  public user (): BelongsTo<User> {
    return new BelongsTo(User, this)
  }
}
