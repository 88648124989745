/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/CropCollectionCropTag.json'

export default class CropCollectionCropTag extends Model {
  static entity = 'crop_collection_crop_tags'

  static primaryKey = ['crop_collection_id', 'crop_tag_id']

  entity = 'crop_collection_crop_tags'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      crop_collection_id: this.number(null),
      crop_tag_id: this.number(null),

    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare crop_collection_id: number
  declare crop_tag_id: number
}
