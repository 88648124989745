/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/FallowCollectionEvent.json'

import CropCollection from './CropCollection'

export default class FallowCollectionEvent extends Model {
  static entity = 'fallow_collection_events'

  static primaryKey = ['crop_collection_id', 'occurrence']

  entity = 'fallow_collection_events'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      occurrence: this.number(null),
      crop_collection_id: this.number(null),
      area: this.number(null),

      crop_collection: this.belongsTo(CropCollection, 'crop_collection_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare occurrence: number
  declare crop_collection_id: number
  declare area: number

  declare crop_collection: CropCollection
}
