/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/SoldPlantSale.json'

export default class SoldPlantSale extends Model {
  static entity = 'sold_plant_sales'

  static primaryKey = 'id'

  entity = 'sold_plant_sales'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      farm_id: this.number(null),
      year: this.string(null),
      plant: this.string(null),
      date_sold: this.attr(null),
      selling_price: this.number(null),
      plant_category_id: this.number(null),
      period_id: this.number(null),

    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare farm_id: number
  declare year: string
  declare plant: string
  declare date_sold: string
  declare selling_price: number
  declare plant_category_id: number
  declare period_id: number
}
