import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'

export default class PhoneNumber extends Model<{
  id: number
  created_at: string
  updated_at: string
  type: string
  number: string
  phone_numberable_type: string
  phone_numberable_id: number
  is_primary: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'phone_numbers'
  }
}
