import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { HasOne } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasOne'
import AdjacentPaddock from './AdjacentPaddock'

export default class CropCollectionSummary extends Model<{
  id: number
  farm_id: number
  period_id: number
  current_crop: string
  current_crop_color: string
  paddock_name: string
  paddock_id: number
  paddock_property: string
  paddock_area: number
  sowing_variety: number
  sowing_secondary_variety: number
  sowing_area: number
  sowing_date: string
  sowing_rate: number
  sowing_row_spacing: number
  grain_harvested_area: number
  grain_harvested_date: number
  grain_tonnes: number
  grain_yield: number
  grazing_number_of_events: number
  total_nitrogen: number
  total_phosphate: number
  enterprise: string
  current_crop_id: number
  lint_harvested_area: number
  lint_harvested_date: number
  lint_bales: number
  lint_seed_tonnes: number
  lint_yield: number
  irrigation_total_ml_applied: number
  hay_cut_area: number
  hay_cut_date: number
  hay_tonnes: number
  hay_yield: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'crop_collection_summaries'
  }

  public adjacent_paddock (): HasOne<AdjacentPaddock> {
    return new HasOne(AdjacentPaddock, this)
  }
}
