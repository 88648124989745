/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/CropTerminationType.json'

import CustomTerminationEvent from './CustomTerminationEvent'

export default class CropTerminationType extends Model {
  static entity = 'crop_termination_types'

  static primaryKey = 'id'

  entity = 'crop_termination_types'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      label: this.string(null),

      crop_termination_events: this.hasMany(CustomTerminationEvent, 'crop_termination_type_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare label: string

  declare crop_termination_events: CustomTerminationEvent[]
}
