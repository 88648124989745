import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'

export default class LivestockBreedingDetailsCollection extends Model<{
  id: number
  created_at: string
  updated_at: string
  enterprise: string
  farm_id: number
  year: string
  date_joined: string
  number_joined: number
  number_of_sires: number
  length_of_joining: number
  number_born: number
  date_weaned: string
  number: number
  weaning_weight: number
  weaning_percentage: number
  notes: string
  period_id: number
  enterprise_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'livestock_breeding_details_collections'
  }
}
