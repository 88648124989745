import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import CropCollectionEnterprise from './CropCollectionEnterprise'

export default class CropCollectionFallowType extends Model<{
  id: number
  created_at: string
  updated_at: string
  name: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'crop_collection_fallow_types'
  }

  public crop_collection_enterprises (): HasMany<CropCollectionEnterprise> {
    return new HasMany(CropCollectionEnterprise, this)
  }

  public crop_collections (): HasMany<CropCollectionEnterprise> {
    return new HasMany(CropCollectionEnterprise, this)
  }
}
