/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/User.json'

import PhoneNumber from './PhoneNumber'
import Address from './Address'
import DetailsUser from './DetailsUser'
import Farm from './Farm'
import BusinessUser from './BusinessUser'
import Business from './Business'
import Opportunity from './Opportunity'
import EmailAddress from './EmailAddress'
import Project from './Project'
import Role from './Role'
import QueableJob from './QueableJob'

export default class User extends Model {
  static entity = 'users'

  static primaryKey = 'id'

  entity = 'users'

  static fields () {
    return {
      id: this.number(null),
      admin: this.boolean(null),
      is_impersonating: this.boolean(null),
      name: this.string(null),
      email: this.string(null),
      email_verified_at: this.attr(null),
      remember_token: this.string(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      agreed_to_terms: this.boolean(null),
      deleted_at: this.attr(null),
      active: this.boolean(null),
      color: this.attr(null),
      password: this.attr(null),

      phone_numbers: this.morphMany(PhoneNumber, 'phone_numberable_id', 'phone_numberable_type'),
      addresses: this.morphMany(Address, 'addressable_id', 'addressable_type'),
      farms: this.belongsToMany(Farm, DetailsUser, 'user_id', 'details_id'),
      businesses: this.belongsToMany(Business, BusinessUser, 'user_id', 'business_id'),
      associated_businesses: this.belongsToMany(Business, BusinessUser, 'user_id', 'business_id'),
      managed_businesses: this.hasMany(Business, 'manager_id'),
      opportunities: this.hasMany(Opportunity, 'employee_id'),
      emails: this.morphMany(EmailAddress, 'emailable_id', 'emailable_type'),
      projects: this.hasMany(Project, 'customer_id'),
      roles: this.hasMany(Role, 'user_id'),
      queable_jobs: this.hasMany(QueableJob, 'user_id'),
    }
  }

  declare id: number
  declare admin: string
  declare is_impersonating: string
  declare name: string
  declare email: string
  declare email_verified_at: string
  declare remember_token: string
  declare created_at: string
  declare updated_at: string
  declare agreed_to_terms: string
  declare deleted_at: string
  declare active: string
  declare color: string
  declare password: string

  declare phone_numbers: PhoneNumber[]
  declare addresses: Address[]
  declare farms: Farm[]
  declare businesses: Business[]
  declare associated_businesses: Business[]
  declare managed_businesses: Business[]
  declare opportunities: Opportunity[]
  declare emails: EmailAddress[]
  declare projects: Project[]
  declare roles: Role[]
  declare queable_jobs: QueableJob[]
}
