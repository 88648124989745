/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/FertiliserChemicalApplication.json'

import FertiliserCollectionEvent from './FertiliserCollectionEvent'
import ChemicalElement from './ChemicalElement'

export default class FertiliserChemicalApplication extends Model {
  static entity = 'fertiliser_chemical_applications'

  static primaryKey = 'id'

  entity = 'fertiliser_chemical_applications'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      fertiliser_collection_event_id: this.number(null),
      chemical_element_id: this.number(null),
      unit_ha: this.number(null),
      farm_id: this.number(null),

      fertiliser_collection_event: this.belongsTo(FertiliserCollectionEvent, 'fertiliser_collection_event_id', 'id'),
      chemical_element: this.belongsTo(ChemicalElement, 'chemical_element_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare fertiliser_collection_event_id: number
  declare chemical_element_id: number
  declare unit_ha: number
  declare farm_id: number

  declare fertiliser_collection_event: FertiliserCollectionEvent
  declare chemical_element: ChemicalElement
}
