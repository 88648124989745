import { Repository } from 'pinia-orm'
import { Model } from '../types'
import { Model as OrionModel } from '@tailflow/laravel-orion/lib/model'
import { take } from '@agripath/common-vue-components'
import { removeEmpty } from '../helpers/removeEmpty'
import { FilterOperator } from '@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator'

export function repoFactory<R extends typeof Model, O extends OrionModel> (model: R, Api: O & (new (...args: unknown[]) => O)) {
  return class GenericRepository<M extends InstanceType<R>> extends Repository<M> {
    use = model

    async fetch (options: {ids?: number[], periodIds?: number[], where?: {[field: string]: string | number}, scope?: {[field: string]: number[]}} = {}) {
      this.flush()
      const query = Api.$query()
      if (options.ids) {
        query.scope('IdIn', [options.ids])
      }
      if (options.scope) {
        for (const [field, value] of Object.entries(options.scope)) {
          query.scope(field, [value])
        }
      }
      if (options.periodIds) {
        query.scope('PeriodIdIn', [options.periodIds])
      }
      if (options.where) {
        for (const [field, value] of Object.entries(options.where)) {
          query.filter(field, FilterOperator.Equal, value)
        }
      }
      const response = await query.search(0)
      this.insert(response.map(m => m.$attributes))
    }

    async persistBatchUpdate (entities: M[], fields:string[]|null = null): Promise<M[]> {
      const ApiModels = entities.map(m => new Api(m))
      const response = Api.$query().batchUpdate(ApiModels)
      return this.save((await response).map(m => {
        if (fields) {
          // take(m.$attributes, ['id', ...fields])
        }
        return m.$attributes
      }))
    }

    async persistSingleUpdate (entity: M, fields:string[]|null = null): Promise<M> {
      const response = await new Api(entity).$save()
      if (fields) {
        take(response.$attributes, ['id', ...fields])
      }
      return this.where('id', entity.id!).update(response.$attributes)[0]
    }

    async persistBatchCreate (entities: M[]) {
      const ApiModels = entities.map(m => new Api(m))
      const response = await Api.$query().batchStore(ApiModels)
      return this.insert((await response).map(m => {
        return m.$attributes
      }))
    }

    async persistCreate<I extends M> (entity: I) {
      const response = await Api.$query().store(entity as Record<string, unknown>)
      return this.insert(response.$attributes)
    }

    persist<I extends M> (entity: Partial<I>, fields?:string[]|null, keepEmpty?: boolean): Promise<M>
    persist<I extends M> (entity: I[], fields?:string[]|null, keepEmpty?: boolean): Promise<M[]>
    async persist<I extends M> (entity: M | M[], fields:string[]|null = null, keepEmpty = false): Promise<M | M[] | undefined> {
      const isArray = (entity: M | M[]): entity is I[] => {
        return Array.isArray(entity)
      }

      const isNotArray = (entity: M | M[]): entity is I => {
        return !Array.isArray(entity)
      }

      if (fields) {
        fields = ['id', ...fields]
      }

      if (isArray(entity)) {
        if (fields) {
          entity.forEach(cc => {
            take(cc, fields!)
          })
        }
        // if (!keepEmpty) {
        //   removeEmpty(entity)
        // }
        if (entity.every(e => e.id)) {
          return await this.persistBatchUpdate((keepEmpty ? entity : removeEmpty<M, I>(entity)) as M[], fields)
        } else {
          return await this.persistBatchCreate((keepEmpty ? entity : removeEmpty<M, I>(entity)) as M[])
        }
      }

      if (isNotArray(entity)) {
        if (fields) {
          take(entity, fields)
        }
        // if (!keepEmpty) {
        //   removeEmpty(entity)
        // }
        if (entity.id) {
          return await this.persistSingleUpdate((keepEmpty ? entity : removeEmpty<M, I>(entity)) as M, fields)
        } else {
          return await this.persistCreate((keepEmpty ? entity : removeEmpty<M, I>(entity)) as M)
        }
      }
    }

    async singleDestroy (id: number) {
      const response = await Api.$query().destroy(id)
      return this.where('id', response.$attributes.id as number).delete()
    }

    async batchDestroy (id: (number)[]) {
      const response = await Api.$query().batchDelete(id)
      return this.where('id', w => response.map(m => m.$attributes.id as number).includes(w)).delete()
    }

    async remove (id: number | (number)[]) {
      const isArray = (id: number | (number)[]): id is (number)[] => {
        return Array.isArray(id)
      }

      if (isArray(id)) {
        return await this.batchDestroy(id)
      } else {
        return await this.singleDestroy(id)
      }
    }
  }
}
