import { route } from 'quasar/wrappers'
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'

import { useAuthStore } from 'src/stores/auth'
import { useAppStore } from 'src/stores/appStore'
import routes from './routes'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route((/* { store, ssrContext } */) => {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
      ? createWebHistory
      : createWebHashHistory

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  })

  Router.beforeEach((to, from) => {
    const auth = useAuthStore()
    const app = useAppStore()
    const { currentFarmId, currentPeriodId } = storeToRefs(app)

    const publicRoutes = [
      'auth.login',
      'auth.resetPassword',
    ]

    if (to.meta.requiresAdmin && !auth.isAdmin) {
      return { name: 'home' }
    }

    const loggedIn = auth.loggedIn

    if ((loggedIn && to.name === 'auth.logout') || (!loggedIn && (to.name === 'auth.login' || to.name === 'auth.resetPassword'))) { return true }

    if (!loggedIn) {
      return { name: 'auth.login' }
    }

    if (loggedIn) {
      if ((to.params.farmId && typeof to.params.farmId === 'string')) {
        const farmId = Number.parseInt(to.params.farmId)
        if (currentFarmId.value !== farmId) {
          localStorage.setItem('farmId', farmId.toString())
          currentFarmId.value = farmId
        }
      }

      if ((to.params.periodId && typeof to.params.periodId === 'string')) {
        const periodId = Number.parseInt(to.params.periodId)

        if (currentPeriodId.value !== periodId) {
          localStorage.setItem('periodId', periodId.toString())
          currentPeriodId.value = periodId
        }
      }

      if (to.name?.toString() && publicRoutes.includes(to.name?.toString())) { return { name: 'home' } } else if (
        !auth.currentBusiness &&
        !auth.isAdmin &&
        to.name !== 'select.business' &&
        to.name !== 'auth.logout'
      ) { return { name: 'select.business' } } else { return true }
    } else {
      return false
    }
  })

  return Router
})
