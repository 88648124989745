import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import { BelongsToMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany'
import CropCollection from './CropCollection'
import LivestockInventoryCollection from './LivestockInventoryCollection'
import LivestockFeedCollection from './LivestockFeedCollection'
import LivestockAgistmentCollection from './LivestockAgistmentCollection'
import LivestockAgistedAwayCollection from './LivestockAgistedAwayCollection'
import LivestockBreedingDetailsCollection from './LivestockBreedingDetailsCollection'
import LivestockPurchasesCollection from './LivestockPurchasesCollection'
import LivestockSalesCollection from './LivestockSalesCollection'
import LivestockWoolCollection from './LivestockWoolCollection'
import NewPlantPurchase from './NewPlantPurchase'
import SoldPlantSale from './SoldPlantSale'
import Paddock from './Paddock'
import CropTypeFarmPeriod from './CropTypeFarmPeriod'
import CropType from './CropType'
import FarmPeriod from './FarmPeriod'
import Farm from './Farm'
import FarmLivestockEnterprisePeriod from './FarmLivestockEnterprisePeriod'

export default class Period extends Model<{
  id: number
  name: string
  year_name: string
  start_date: string
  end_date: string
  sowing_start_date: string
  sowing_end_date: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'periods'
  }

  public crop_collections (): HasMany<CropCollection> {
    return new HasMany(CropCollection, this)
  }

  public livestock_inventory_collections (): HasMany<LivestockInventoryCollection> {
    return new HasMany(LivestockInventoryCollection, this)
  }

  public livestock_feed_collections (): HasMany<LivestockFeedCollection> {
    return new HasMany(LivestockFeedCollection, this)
  }

  public livestock_agistment_collections (): HasMany<LivestockAgistmentCollection> {
    return new HasMany(LivestockAgistmentCollection, this)
  }

  public livestock_agisted_away_collections (): HasMany<LivestockAgistedAwayCollection> {
    return new HasMany(LivestockAgistedAwayCollection, this)
  }

  public livestock_breeding_details_collections (): HasMany<LivestockBreedingDetailsCollection> {
    return new HasMany(LivestockBreedingDetailsCollection, this)
  }

  public livestock_purchases_collections (): HasMany<LivestockPurchasesCollection> {
    return new HasMany(LivestockPurchasesCollection, this)
  }

  public livestock_sales_collections (): HasMany<LivestockSalesCollection> {
    return new HasMany(LivestockSalesCollection, this)
  }

  public livestock_wool_collections (): HasMany<LivestockWoolCollection> {
    return new HasMany(LivestockWoolCollection, this)
  }

  public new_plant_purchases (): HasMany<NewPlantPurchase> {
    return new HasMany(NewPlantPurchase, this)
  }

  public sold_plant_sales (): HasMany<SoldPlantSale> {
    return new HasMany(SoldPlantSale, this)
  }

  public paddocks (): HasMany<Paddock> {
    return new HasMany(Paddock, this)
  }

  public crop_types (): BelongsToMany<CropType> {
    return new BelongsToMany(CropType, this)
  }

  public farms (): BelongsToMany<Farm> {
    return new BelongsToMany(Farm, this)
  }

  public farm_livestock_enterprise_periods (): HasMany<FarmLivestockEnterprisePeriod> {
    return new HasMany(FarmLivestockEnterprisePeriod, this)
  }
}
