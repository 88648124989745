import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import CropType from './CropType'
import AssetType from './AssetType'
import CropCollectionFallowType from './CropCollectionFallowType'
import CropCollectionStatus from './CropCollectionStatus'

export default class CropCollectionEnterprise extends Model<{
  asset_type_id: number
  fallow_type_id: number
  crop_type_id: number
  status_id: number,
  name: string,
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'crop_collection_enterprises'
  }

  public crop_type (): BelongsTo<CropType> {
    return new BelongsTo(CropType, this)
  }

  public asset_type (): BelongsTo<AssetType> {
    return new BelongsTo(AssetType, this)
  }

  public fallow_type (): BelongsTo<CropCollectionFallowType> {
    return new BelongsTo(CropCollectionFallowType, this)
  }

  public status (): BelongsTo<CropCollectionStatus> {
    return new BelongsTo(CropCollectionStatus, this)
  }
}
