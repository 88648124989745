import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import Paddock from './Paddock'

export default class PaddockPriorPaddock extends Model<{
  parent_paddock_id: number
  prior_paddock_id: number
  is_primary?: boolean
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'paddock_prior_paddocks'
  }

  public parent_paddock (): BelongsTo<Paddock> {
    return new BelongsTo(Paddock, this)
  }

  public prior_paddock (): BelongsTo<Paddock> {
    return new BelongsTo(Paddock, this)
  }
}
