import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import CropType from './CropType'
import Farm from './Farm'
import Period from './Period'

export default class CropTypeFarmPeriod extends Model<{
  crop_type_id: number
  farm_id: number
  period_id: number
  all_questions_answered?: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'crop_type_farm_periods'
  }

  public crop_type (): BelongsTo<CropType> {
    return new BelongsTo(CropType, this)
  }

  public farm (): BelongsTo<Farm> {
    return new BelongsTo(Farm, this)
  }

  public period (): BelongsTo<Period> {
    return new BelongsTo(Period, this)
  }
}
