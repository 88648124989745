/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/EmailAddress.json'

export default class EmailAddress extends Model {
  static entity = 'email_addresses'

  static primaryKey = 'id'

  entity = 'email_addresses'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      type: this.string(null),
      email: this.string(null),
      email_addressable_type: this.string(null),
      email_addressable_id: this.number(null),
      is_primary: this.boolean(null),

    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare type: string
  declare email: string
  declare email_addressable_type: string
  declare email_addressable_id: number
  declare is_primary: string
}
