import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import User from './User'

export default class Role extends Model<{
  id: number
  created_at: string
  updated_at: string
  role: string
  user_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'roles'
  }

  public users (): BelongsTo<User> {
    return new BelongsTo(User, this)
  }
}
