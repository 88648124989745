import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import Paddock from './Paddock'
import CropCollectionSummary from './CropCollectionSummary'

export default class AdjacentPaddock extends Model<{
  name: string
  path: string
  paddock_id: number
  parent_paddock_id: number
  prior_paddock_id: number
  is_primary: string
  ancestors_and_self: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'adjacent_paddocks'
  }

  public paddock (): BelongsTo<Paddock> {
    return new BelongsTo(Paddock, this)
  }

  public crop_collection_summaries (): HasMany<CropCollectionSummary> {
    return new HasMany(CropCollectionSummary, this)
  }
}
