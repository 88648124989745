import { FarmPeriod as Model } from '../models'
import { orionFarmPeriod as Api } from '..'
import { repoFactory } from '../repositories/GenericRepository'
import { useRepo } from 'pinia-orm'

export function useFarmPeriodRepo () {
  return useRepo(repoFactory(Model, Api))
}

export function fetchFarmPeriod () {
  return useFarmPeriodRepo().fetch()
}
