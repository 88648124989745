import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import Farm from './Farm'

export default class ExtensiveLivestock extends Model<{
  id: number
  created_at: string
  updated_at: string
  farm_id: number
  Zone: string
  Stock: string
  DSE: number
  Year: string
  Change_Value: number
  Sales: number
  Purchases: number
  TradingProfit: number
  Wool_Income: number
  Semen_Income: number
  Other_Income: number
  GrossIncome: number
  GrossMargin: number
  GM_perDSE: number
  GM_less_wagedepn: number
  GM_less_PE: number
  GM_Ha: number
  DSEperha: number
  Exp_Seed: number
  Exp_ChemicalFallow: number
  Exp_ChemicalCrop: number
  Exp_ContractingFallow: number
  Exp_ContractingSpraying: number
  Exp_ContractingFarming: number
  Exp_ContractingHarvesting: number
  Exp_FertiliserN: number
  Exp_FertiliserP: number
  Exp_FertiliserOther: number
  Exp_WaterPumping: number
  Exp_WaterExpenses: number
  Exp_AnimalHealth: number
  Exp_LivestockContractServices: number
  Exp_LivestockFeedAgistment: number
  Exp_CasualLabour: number
  Exp_FreightCartage: number
  Exp_FuelLube: number
  Exp_Insurance: number
  Exp_RMPlant: number
  Exp_RMIrrigation: number
  Exp_RMStructures: number
  Exp_SellingCost: number
  Exp_Ginning: number
  Exp_GMOLicense: number
  Exp_Packaging: number
  Exp_Sundry: number
  Exp_Wages: number
  Exp_Depreciation: number
  Exp_CropsGrazed: number
  Exp_OnHandFeed: number
  Exp_PastureCosts: number
  Exp_Total: number
  TPMLCosts: number
  AreaCosts: number
  YieldCosts: number
  StockingRate: number
  Mortality: number
  OffspringPercent: number
  WeaningPercent: number
  Survivability: number
  MeatProduced_ha: number
  MeatProduced_100mm: number
  MeatSold_ha: number
  MeatSold_100mm: number
  AveValueStockSold_Head: number
  AveValueStockSold_Kg: number
  AveValueStockPurchased_Head: number
  AveValueStockPurchased_Kg: number
  ProductionCost: number
  DSE_labour: number
  Land_HighPerformance: number
  Land_Improved: number
  Land_SemiImproved: number
  Land_Native: number
  Land_Waste: number
  Land_Total: number
  Plant_Used: number
  Labour_Months: number
  P_removed: number
  P_replaced: number
  P_deficit: number
  Wool_shorn: number
  Wool_Cut_kg: number
  Wool_micron: number
  Wool_yield: number
  Wool_strength: number
  Wool_VM: number
  Wool_Kgsperhd: number
  Wool_cperkg_clean: number
  Wool_hd: number
  Wool_kgsperha: number
  AWool_shorn: number
  Awool_Cut_kg: number
  Awool_micron: number
  Awool_yield: number
  Awool_strength: number
  AWool_VM: number
  DSE_July: number
  DSE_August: number
  DSE_September: number
  DSE_October: number
  DSE_November: number
  DSE_December: number
  DSE_January: number
  DSE_Febuary: number
  DSE_March: number
  DSE_April: number
  DSE_May: number
  DSE_June: number
  Change_Value_DSE: number
  Sales_DSE: number
  Purchases_DSE: number
  Zone2: number
  Type: number
  Exp_ChemicalOther1: number
  Exp_ChemicalOther2: number
  Exp_ContractingOther: number
  Exp_FertiliserK: number
  Exp_FertiliserOther2: number
  Exp_FertiliserOther3: number
  Exp_WaterTempPurchases: number
  Exp_RMLivestock: number
  Exp_Processing: number
  Exp_Agronomy: number
  Exp_Sundry2: number
  Exp_Sundry3: number
  FeedCosts: number
  DSE_February: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'extensive_livestocks'
  }

  public farm (): BelongsTo<Farm> {
    return new BelongsTo(Farm, this)
  }
}
