/* eslint-disable camelcase */

import { Model } from '../../types'

import CropCollection from './CropCollection'
import Variety from '../Variety'
import CropCategory from '../CropCategory'

export default class CropType extends Model {
  static entity = 'crop_types'

  static primaryKey = 'id'

  entity = 'crop_types'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      name: this.string(null),
      color: this.string(null),
      options: this.attr(null),
      harvest_type_id: this.number(null),
      category_id: this.number(null),

      crop_collections: this.hasMany(CropCollection, 'current_crop_id'),
      crop_category: this.belongsTo(CropCategory, 'category_id'),
      varieties: this.hasMany(Variety, 'crop_type_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare name: string
  declare color: string
  declare options: string
  declare harvest_type_id: number
  declare category_id: number

  declare crop_category: CropCategory
  declare crop_collections: CropCollection[]
  declare varieties: Variety[]
}
