/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/LivestockEnterprise.json'

import AssetType from './AssetType'
import LivestockSpeciesType from './LivestockSpeciesType'
import LivestockPurpose from './LivestockPurpose'

export default class LivestockEnterprise extends Model {
  static entity = 'livestock_enterprises'

  static primaryKey = 'id'

  entity = 'livestock_enterprises'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      asset_type_id: this.number(null),
      species_id: this.number(null),
      purpose_id: this.number(null),

      asset_type: this.belongsTo(AssetType, 'asset_type_id'),
      species: this.belongsTo(LivestockSpeciesType, 'species_id'),
      purpose: this.belongsTo(LivestockPurpose, 'purpose_id'),
    }
  }

  get name () {
    if (!this.asset_type || !this.species || !this.purpose) return '-'

    const asArray = [
      this.asset_type.name,
      this.species.name,
      this.purpose.name,
    ]

    return asArray.join(' ')
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare asset_type_id: number
  declare species_id: number
  declare purpose_id: number

  declare asset_type: AssetType
  declare species: LivestockSpeciesType
  declare purpose: LivestockPurpose
}
