import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import { BelongsToMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany'
import { MorphMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/morphMany'
import Farm from './Farm'
import User from './User'
import Period from './Period'
import ProductProject from './ProductProject'
import Product from './Product'
import ProfitFocusGroup from './ProfitFocusGroup'
import ProjectStatus from './ProjectStatus'
import Bill from './Bill'
import Expense from './Expense'
import Business from './Business'

export default class Project extends Model<{
  id: number
  title: string
  estimated_completion_date: string
  collection_date: string
  validation_date: string
  archived: string
  customer_id: number
  details_id: number
  collector_id: number
  owner_id: number
  validator_id: number
  project_status_id: number
  profit_focus_group_id: number
  fee: number
  po_number: number
  business_id: number
  period_id: number
  notes: string
  dueFees: number
  amountInvoicedPercentage: number
  amountBilled: number
  outstandingBilling: number
  totalExpenses: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'projects'
  }

  public farm (): BelongsTo<Farm> {
    return new BelongsTo(Farm, this)
  }

  public collector (): BelongsTo<User> {
    return new BelongsTo(User, this)
  }

  public period (): BelongsTo<Period> {
    return new BelongsTo(Period, this)
  }

  public owner (): BelongsTo<User> {
    return new BelongsTo(User, this)
  }

  public validator (): BelongsTo<User> {
    return new BelongsTo(User, this)
  }

  public products (): BelongsToMany<Product> {
    return new BelongsToMany(Product, this)
  }

  public customer (): BelongsTo<User> {
    return new BelongsTo(User, this)
  }

  public profit_focus_group (): BelongsTo<ProfitFocusGroup> {
    return new BelongsTo(ProfitFocusGroup, this)
  }

  public project_status (): BelongsTo<ProjectStatus> {
    return new BelongsTo(ProjectStatus, this)
  }

  public bills (): MorphMany<Bill> {
    return new MorphMany(Bill, this)
  }

  public expenses (): MorphMany<Expense> {
    return new MorphMany(Expense, this)
  }

  public business (): BelongsTo<Business> {
    return new BelongsTo(Business, this)
  }
}
