import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import CropCollection from './CropCollection'
import FertiliserProduct from './FertiliserProduct'
import FertiliserChemicalApplication from './FertiliserChemicalApplication'

export default class FertiliserCollectionEvent extends Model<{
  occurrence: number
  crop_collection_id: number
  application_date: string
  pre_applied: string
  fertiliser_product_id: number
  farm_id: number
},
DefaultPersistedAttributes,
{
  crop_collection: CropCollection
  fertiliser_product: FertiliserProduct
  fertiliser_chemical_applications: FertiliserChemicalApplication[]
} > {
  public $resource (): string {
    return 'fertiliser_collection_events'
  }

  public crop_collection (): BelongsTo<CropCollection> {
    return new BelongsTo(CropCollection, this)
  }

  public fertiliser_product (): BelongsTo<FertiliserProduct> {
    return new BelongsTo(FertiliserProduct, this)
  }

  public fertiliser_chemical_applications (): HasMany<FertiliserChemicalApplication> {
    return new HasMany(FertiliserChemicalApplication, this)
  }
}
