/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/GrazingCollectionEvent.json'

import CropCollection from './CropCollection'
import LivestockClass from './LivestockClass'

export default class GrazingCollectionEvent extends Model {
  static entity = 'grazing_collection_events'

  static primaryKey = ['crop_collection_id', 'occurrence']

  entity = 'grazing_collection_events'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      occurrence: this.number(null),
      crop_collection_id: this.number(null),
      grazing_area: this.number(null),
      grazing_start_date: this.attr(null),
      grazing_end_date: this.attr(null),
      total_weight_on: this.number(null),
      total_weight_off: this.number(null),
      number_of_stock: this.number(null),
      notes: this.string(null),
      livestock_class_id: this.number(null),

      crop_collection: this.belongsTo(CropCollection, 'crop_collection_id'),
      livestock_class: this.belongsTo(LivestockClass, 'livestock_class_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare occurrence: number
  declare crop_collection_id: number
  declare grazing_area: number
  declare grazing_start_date: string
  declare grazing_end_date: string
  declare total_weight_on: number
  declare total_weight_off: number
  declare number_of_stock: number
  declare notes: string
  declare livestock_class_id: number

  declare crop_collection: CropCollection
  declare livestock_class: LivestockClass
}
