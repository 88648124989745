/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/Asset.json'

import Period from './Period'
import Farm from './Farm'

export default class Asset extends Model {
  static entity = 'assets'

  static primaryKey = 'id'

  entity = 'assets'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      period_id: this.number(null),
      farm_id: this.number(null),

      periods: this.belongsTo(Period, 'period_id'),
      farms: this.belongsTo(Farm, 'farm_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare period_id: number
  declare farm_id: number

  declare periods: Period
  declare farms: Farm
}
