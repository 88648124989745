import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import AssetType from './AssetType'
import ValuationUsage from './ValuationUsage'
import ValuationCollSheetMap from './ValuationCollSheetMap'

export default class ValuationCategory extends Model<{
  name: string,
  valuation_usage_id: number
  valuation_coll_sheet_id: number
  asset_type_id: number
},
DefaultPersistedAttributes,
{
  asset_type: AssetType
  valuation_usage: ValuationUsage
  valuation_coll_sheet_map: ValuationCollSheetMap
} > {
  public $resource (): string {
    return 'valuation_categories'
  }

  public asset_type (): BelongsTo<AssetType> {
    return new BelongsTo(AssetType, this)
  }

  public valuation_usage (): BelongsTo<ValuationUsage> {
    return new BelongsTo(ValuationUsage, this)
  }

  public valuation_coll_sheet_map (): BelongsTo<ValuationCollSheetMap> {
    return new BelongsTo(ValuationCollSheetMap, this)
  }
}
