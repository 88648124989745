import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import CropType from './CropType'

export default class Variety extends Model<{
  id: number
  created_at: string
  updated_at: string
  name: string
  crop_type_id: number
  validated: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'varieties'
  }

  public crop_type (): BelongsTo<CropType> {
    return new BelongsTo(CropType, this)
  }
}
