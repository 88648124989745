import { defineStore } from 'pinia'
import { useAppStore } from 'src/stores/appStore'
import { useSowingCollectionEventRepo, SowingCollectionEvent } from '@agripath/orm'
import { PiniaOrmForm } from 'pinia-orm-helpers'

export const useSowingEventStore = defineStore('sowingEvent', () => {
  const app = useAppStore()
  const repo = useSowingCollectionEventRepo()

  const { currentPeriodId, currentFarmId } = storeToRefs(app)

  const fetchSowingCollectionEvents = async () => {
    if (currentPeriodId.value) {
      await repo.fetch({ periodIds: [currentPeriodId.value] })
    }
  }

  const fetch = async () => {
    await Promise.all([
      fetchSowingCollectionEvents(),
    ])
  }

  const sowingCollectionEvents = computed(() => {
    return repo
      .where('period_id', app.currentPeriodId)
      .where('farm_id', app.currentFarmId)
      .get()
  })

  const query = (withoutScope = false) => {
    const query = repo.query()
    if (withoutScope) {
      return query
    }
    return query
      .where('period_id', app.currentPeriodId)
      .where('farm_id', app.currentFarmId)
  }

  const make = (record?: PiniaOrmForm<SowingCollectionEvent>) => {
    const farmPeriod = { period_id: app.currentPeriodId, farm_id: app.currentFarmId }
    return repo.make({ ...record, ...farmPeriod })
  }

  async function persist(form: PiniaOrmForm<SowingCollectionEvent>, fields?: string[] | null): Promise<SowingCollectionEvent>;
  async function persist(form: PiniaOrmForm<SowingCollectionEvent>[], fields?: string[] | null): Promise<SowingCollectionEvent[]>;
  async function persist (form: PiniaOrmForm<SowingCollectionEvent> | PiniaOrmForm<SowingCollectionEvent>[], fields: string[] | null = null): Promise<SowingCollectionEvent|SowingCollectionEvent[]> {
    return await repo.persist(form, fields)
  }

  return {
    fetch,
    sowingCollectionEvents,
    persist,
    make,
    query,
  }
})
