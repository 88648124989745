/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/LivestockPurchasesCollection.json'

export default class LivestockPurchasesCollection extends Model {
  static entity = 'livestock_purchases_collections'

  static apiEntity = 'livestock_purchases'

  static primaryKey = 'id'

  entity = 'livestock_purchases_collections'

  apiEntity = 'livestock_purchases'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      enterprise: this.string(null),
      farm_id: this.number(null),
      year: this.string(null),
      class_of_stock: this.string(null),
      number_purchased: this.number(null),
      date_in: this.attr(null),
      total_value: this.number(null),
      live_weight_average: this.number(null),
      price_per_kilogram_live_weight: this.number(null),
      notes: this.string(null),
      period_id: this.number(null),
      enterprise_id: this.number(null),

    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare enterprise: string
  declare farm_id: number
  declare year: string
  declare class_of_stock: string
  declare number_purchased: number
  declare date_in: string
  declare total_value: number
  declare live_weight_average: number
  declare price_per_kilogram_live_weight: number
  declare notes: string
  declare period_id: number
  declare enterprise_id: number
}
