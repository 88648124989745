import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'

export default class LivestockSalesCollection extends Model<{
  id: number
  created_at: string
  updated_at: string
  enterprise: string
  farm_id: number
  year: string
  class_of_stock: string
  number_sold: number
  date_out: string
  total_value: number
  live_weight_average: number
  price_per_kilogram_live_weight: number
  notes: string
  period_id: number
  enterprise_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'livestock_sales_collections'
  }
}
