/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/ChemicalElement.json'

import ChemicalElementFertiliserProduct from './ChemicalElementFertiliserProduct'
import FertiliserProduct from './FertiliserProduct'
import FertiliserChemicalApplication from './FertiliserChemicalApplication'

export default class ChemicalElement extends Model {
  static entity = 'chemical_elements'

  static primaryKey = 'id'

  entity = 'chemical_elements'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(null),

      fertiliser_products: this.belongsToMany(FertiliserProduct, ChemicalElementFertiliserProduct, 'chemical_element_id', 'fertiliser_product_id'),
      fertiliser_chemical_applications: this.hasMany(FertiliserChemicalApplication, 'chemical_element_id'),
    }
  }

  declare id: number
  declare name: string

  declare fertiliser_products: FertiliserProduct[]
  declare fertiliser_chemical_applications: FertiliserChemicalApplication[]
  declare pivot: ChemicalElementFertiliserProduct
}
