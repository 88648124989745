import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsToMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import ChemicalElementFertiliserProduct from './ChemicalElementFertiliserProduct'
import FertiliserProduct from './FertiliserProduct'
import FertiliserChemicalApplication from './FertiliserChemicalApplication'

export default class ChemicalElement extends Model<{
  id: number
  name: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'chemical_elements'
  }

  public fertiliser_products (): BelongsToMany<FertiliserProduct> {
    return new BelongsToMany(FertiliserProduct, this)
  }

  public fertiliser_chemical_applications (): HasMany<FertiliserChemicalApplication> {
    return new HasMany(FertiliserChemicalApplication, this)
  }
}
