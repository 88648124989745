import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import LivestockClass from './LivestockClass'

export default class LivestockSpeciesType extends Model<{
  id: number
  created_at: string
  updated_at: string
  name: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'livestock_species_types'
  }

  public livestock_classes (): HasMany<LivestockClass> {
    return new HasMany(LivestockClass, this)
  }
}
