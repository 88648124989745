/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/ProjectStatus.json'

import Project from './Project'

export default class ProjectStatus extends Model {
  static entity = 'project_statuses'

  static primaryKey = 'id'

  entity = 'project_statuses'

  static fields () {
    return {
      id: this.number(null),
      title: this.string(null),

      projects: this.hasMany(Project, 'project_status_id'),
    }
  }

  declare id: number
  declare title: string

  declare projects: Project[]
}
