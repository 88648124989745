/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/ChemicalElementFertiliserProduct.json'

export default class ChemicalElementFertiliserProduct extends Model {
  static entity = 'chemical_element_fertiliser_products'

  static apiEntity = 'chem_element_fert_products'

  static primaryKey = 'id'

  entity = 'chemical_element_fertiliser_products'

  apiEntity = 'chem_element_fert_products'

  static fields () {
    return {
      id: this.number(null),
      percentage: this.number(null),
      chemical_element_id: this.number(null),
      fertiliser_product_id: this.number(null),

    }
  }

  declare id: number
  declare percentage: number
  declare chemical_element_id: number
  declare fertiliser_product_id: number
}
