/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/CropCollectionEnterprise.json'

import CropType from './CropType'
import AssetType from './AssetType'
import CropCollectionFallowType from './CropCollectionFallowType'
import CropCollectionStatus from './CropCollectionStatus'

export default class CropCollectionEnterprise extends Model {
  static entity = 'crop_collection_enterprises'

  static primaryKey = 'id'

  entity = 'crop_collection_enterprises'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      asset_type_id: this.number(null),
      fallow_type_id: this.number(null),
      crop_type_id: this.number(null),
      status_id: this.number(null),
      name: this.attr(null),

      crop_type: this.belongsTo(CropType, 'crop_type_id'),
      asset_type: this.belongsTo(AssetType, 'asset_type_id'),
      fallow_type: this.belongsTo(CropCollectionFallowType, 'fallow_type_id'),
      status: this.belongsTo(CropCollectionStatus, 'status_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare asset_type_id: number
  declare fallow_type_id: number
  declare crop_type_id: number
  declare status_id: number
  declare name: string

  declare crop_type: CropType
  declare asset_type: AssetType
  declare fallow_type: CropCollectionFallowType
  declare status: CropCollectionStatus
}
