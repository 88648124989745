type ExpandRecursively<T> = T extends object
  ? T extends infer O ? { [K in keyof O]: ExpandRecursively<O[K]> } : never
  : T;

type RemoveNull<T> = ExpandRecursively<{ [K in keyof T]: Exclude<RemoveNull<T[K]>, null> }>

export function removeEmpty<M, T extends M> (obj: T): RemoveNull<M>
export function removeEmpty<M, T extends M> (obj: T[]): RemoveNull<M>[]
export function removeEmpty<M, T extends M> (obj: T|T[]): RemoveNull<M>[] | RemoveNull<M> {
  const isArray = (obj: M|M[]): obj is T[] => {
    return Array.isArray(obj)
  }

  if (isArray(obj)) {
    const newArray:M[] = []
    obj.forEach(element => {
      removeEmpty(element)
      newArray.push(element)
    })
    return newArray as RemoveNull<M>[]
  } else {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v != null)
        .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v]),
    ) as RemoveNull<M>
  }
}
