import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import LivestockEnterprise from './LivestockEnterprise'
import Farm from './Farm'

export default class FarmLivestockEnterprise extends Model<{
  id: number
  created_at: string
  updated_at: string
  livestock_enterprise_id: number
  farm_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'farm_livestock_enterprises'
  }

  public livestock_enterprise (): BelongsTo<LivestockEnterprise> {
    return new BelongsTo(LivestockEnterprise, this)
  }

  public farm (): BelongsTo<Farm> {
    return new BelongsTo(Farm, this)
  }
}
