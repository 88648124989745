/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/LivestockAgistmentCollection.json'

import Farm from './Farm'

export default class LivestockAgistmentCollection extends Model {
  static entity = 'livestock_agistment_collections'

  static apiEntity = 'livestock_agistments'

  static primaryKey = 'id'

  entity = 'livestock_agistment_collections'

  apiEntity = 'livestock_agistments'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      farm_id: this.number(null),
      year: this.string(null),
      species: this.string(null),
      class_of_stock: this.string(null),
      number_in: this.number(null),
      average_kg_per_head_on_arrival: this.number(null),
      date_in: this.attr(null),
      number_out: this.number(null),
      average_kg_per_head_on_departure: this.number(null),
      date_out: this.attr(null),
      notes: this.string(null),
      period_id: this.number(null),

      farm: this.belongsTo(Farm, 'farm_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare farm_id: number
  declare year: string
  declare species: string
  declare class_of_stock: string
  declare number_in: number
  declare average_kg_per_head_on_arrival: number
  declare date_in: string
  declare number_out: number
  declare average_kg_per_head_on_departure: number
  declare date_out: string
  declare notes: string
  declare period_id: number

  declare farm: Farm
}
