import { CropCollectionStatus as Model } from '../models'
import { orionCropCollectionStatus as Api } from '..'
import { repoFactory } from '../repositories/GenericRepository'
import { useRepo } from 'pinia-orm'

export function useCropCollectionStatusRepo () {
  return useRepo(repoFactory(Model, Api))
}

export function fetchCropCollectionStatus () {
  return useCropCollectionStatusRepo().fetch()
}
