/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/ExistingPlantItem.json'

export default class ExistingPlantItem extends Model {
  static entity = 'existing_plant_items'

  static primaryKey = 'id'

  entity = 'existing_plant_items'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      farm_id: this.number(null),
      year: this.string(null),
      plant: this.string(null),
      replacement_price: this.number(null),
      plant_category_id: this.number(null),

    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare farm_id: number
  declare year: string
  declare plant: string
  declare replacement_price: number
  declare plant_category_id: number
}
