/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/FarmProperty.json'

import Farm from './Farm'

export default class FarmProperty extends Model {
  static entity = 'farm_properties'

  static apiEntity = 'farm_properties'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(null),
      color: this.attr(null),
      colour_id: this.attr(null),
      farm_id: this.number(null),

      farm: this.belongsTo(Farm, 'farm_id'),
    }
  }

  declare id: number
  declare name: string
  declare color: string
  declare colour_id: number
  declare farm_id: number

  declare farm: Farm
}
