/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/WorkCategory.json'

import EmployeeBill from './EmployeeBill'

export default class WorkCategory extends Model {
  static entity = 'work_categories'

  static primaryKey = 'id'

  entity = 'work_categories'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      title: this.string(null),

      employee_bills: this.hasMany(EmployeeBill, 'work_category_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare title: string

  declare employee_bills: EmployeeBill[]
}
