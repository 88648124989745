/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/FileFileTag.json'

export default class FileFileTag extends Model {
  static entity = 'file_file_tag'

  static primaryKey = ['file_id', 'file_tag_id']

  entity = 'file_file_tag'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      file_id: this.number(null),
      file_tag_id: this.number(null),

    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare file_id: number
  declare file_tag_id: number
}
