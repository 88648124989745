import { Paddock as Model } from '../models'
import { orionPaddock as Api } from '..'
import { repoFactory } from '../repositories/GenericRepository'
import { useRepo } from 'pinia-orm'

export function usePaddockRepo () {
  return useRepo(repoFactory(Model, Api))
}

export function fetchPaddock () {
  return usePaddockRepo().fetch()
}
