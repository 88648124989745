import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import Rainfall from './Rainfall'

export default class RainGauge extends Model<{
  id: string
  name: string
  farm_id: number
},
DefaultPersistedAttributes,
{
  rainfalls: Rainfall[]
} > {
  public $resource (): string {
    return 'rain_gauges'
  }

  public rainfalls (): HasMany<Rainfall> {
    return new HasMany(Rainfall, this)
  }
}
