import { RainGauge as Model } from '../models'
import { orionRainGauge as Api } from '..'
import { repoFactory } from '../repositories/GenericRepository'
import { useRepo } from 'pinia-orm'

export function useRainGaugeRepo () {
  return useRepo(repoFactory(Model, Api))
}

export function fetchRainGauge () {
  return useRainGaugeRepo().fetch()
}
