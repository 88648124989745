/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/AssetType.json'

export default class AssetType extends Model {
  static entity = 'asset_types'

  static primaryKey = 'id'

  entity = 'asset_types'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      name: this.string(null),
      color: this.string(null),

    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare name: string
  declare color: string
}
