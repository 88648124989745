/* eslint-disable camelcase */

import { Model } from '../../types'
import { Temporal } from 'temporal-polyfill'
import { DateCast } from '../../casts'

import CropCollection from './CropCollection'

export default class HayCollectionEvent extends Model {
  static entity = 'hay_collection_events'

  static primaryKey = 'id'

  entity = 'hay_collection_events'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      occurrence: this.number(null),
      crop_collection_id: this.number(null),
      cut_date: this.attr(null),
      date: this.attr(null),
      cut_area: this.number(null),
      hay_tonnes: this.number(null),
      farm_id: this.number(null),
      period_id: this.number(null),

      crop_collection: this.belongsTo(CropCollection, 'crop_collection_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare occurrence: number
  declare crop_collection_id: number
  declare cut_date: string
  declare date: string
  declare cut_area: number
  declare hay_tonnes: number
  declare farm_id: number
  declare period_id: number

  declare crop_collection: CropCollection
}
