import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import { BelongsToMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany'
import Farm from './Farm'
import CropCollection from './CropCollection'
import CropCollectionSummary from './CropCollectionSummary'
import AssetType from './AssetType'
import Period from './Period'
import PriorPaddock from './PriorPaddock'
import RainGauge from './RainGauge'
import FarmProperty from './FarmProperty'
import ValuationCategory from './ValuationCategory'

export default class Paddock extends Model<{
  farm_id: number
  name: string
  area: number
  asset_type_id: number
  rain_gauge_id: string
  property_id: string
  soil_type: string
  irrigation_type: string
  sharefarm_percentage: number
  primary_parent_paddock_id: number
  period_id: number
  fallow_efficiency: number
  water_holding: number
  valuation_category_id: number
},
DefaultPersistedAttributes & {
  ancestors: Record<string, unknown>[]
  valuation_category: ValuationCategory
} > {
  public $resource (): string {
    return 'paddocks'
  }

  public farm (): BelongsTo<Farm> {
    return new BelongsTo(Farm, this)
  }

  public crop_collections (): HasMany<CropCollection> {
    return new HasMany(CropCollection, this)
  }

  public crop_collection_summaries (): HasMany<CropCollectionSummary> {
    return new HasMany(CropCollectionSummary, this)
  }

  public asset_type (): BelongsTo<AssetType> {
    return new BelongsTo(AssetType, this)
  }

  public period (): BelongsTo<Period> {
    return new BelongsTo(Period, this)
  }

  public prior_paddocks (): BelongsToMany<Paddock> {
    return new BelongsToMany(PriorPaddock, this)
  }

  public parent_paddocks (): BelongsToMany<Paddock> {
    return new BelongsToMany(Paddock, this)
  }

  public rain_gauge (): BelongsTo<RainGauge> {
    return new BelongsTo(RainGauge, this)
  }

  public property (): BelongsTo<FarmProperty> {
    return new BelongsTo(FarmProperty, this)
  }

  public valuation_category (): BelongsTo<ValuationCategory> {
    return new BelongsTo(ValuationCategory, this)
  }
}
