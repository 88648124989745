/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/Business.json'

import User from './User'
import BusinessUser from './BusinessUser'
import BusinessFarm from './BusinessFarm'
import Farm from './Farm'
import Project from './Project'
import Address from './Address'

export default class Business extends Model {
  static entity = 'businesses'

  static primaryKey = 'id'

  entity = 'businesses'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(null),
      billing_name: this.string(null),
      billing_email: this.string(null),
      billing_user_id: this.number(null),
      billing_address_id: this.number(null),
      business_address_id: this.number(null),
      manager_id: this.number(null),
      farms_string: this.string(null),
      users_string: this.string(null),
      notes: this.string(null),
      billing_notes: this.string(null),
      docs: this.attr(null),

      billing_user: this.belongsTo(User, 'billing_user_id'),
      manager: this.belongsTo(User, 'manager_id'),
      users: this.belongsToMany(User, BusinessUser, 'business_id', 'user_id'),
      farms: this.belongsToMany(Farm, BusinessFarm, 'business_id', 'farm_id'),
      projects: this.hasMany(Project, 'business_id'),
      addresses: this.morphMany(Address, 'addressable_id', 'addressable_type'),
    }
  }

  declare id: number
  declare name: string
  declare billing_name: string
  declare billing_email: string
  declare billing_user_id: number
  declare billing_address_id: number
  declare business_address_id: number
  declare manager_id: number
  declare farms_string: string
  declare users_string: string
  declare notes: string
  declare billing_notes: string
  declare docs: Record<string, unknown>

  declare billing_user: User
  declare manager: User
  declare users: User[]
  declare farms: Farm[]
  declare projects: Project[]
  declare addresses: Address[]
}
