/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/Rainfall.json'

import RainGauge from './RainGauge'
import { Temporal } from 'temporal-polyfill'

import { DateCast } from '../../casts'

export default class Rainfall extends Model {
  static entity = 'rainfalls'

  static primaryKey = ['id']

  static fields () {
    return {
      id: this.number(null),
      total_mm: this.number(null),
      rain_gauge_id: this.number(null),
      date: this.attr(null),

      rain_gauge: this.belongsTo(RainGauge, 'rain_gauge_id'),
    }
  }

  declare id: number
  declare total_mm: number
  declare rain_gauge_id: number
  declare date: string

  declare rain_gauge: RainGauge
}
