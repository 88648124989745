import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'

export default class LivestockAgistedAwayCollection extends Model<{
  id: number
  created_at: string
  updated_at: string
  class_of_stock: string
  number_away: number
  away_kg: number
  date_away: string
  number_back: number
  return_kg: number
  date_back: string
  enterprise: string
  farm_id: number
  year: number
  notes: string
  period_id: number
  enterprise_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'livestock_agisted_away_collections'
  }
}
