/* eslint-disable camelcase */

import { Model } from '../../types'

import CropType from './CropType'

export default class CropCategory extends Model {
  static entity = 'crop_categories'

  static primaryKey = 'id'

  entity = 'crop_categories'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(null),
      color: this.string(null),

      crop_types: this.hasMany(CropType, 'category_id'),
    }
  }

  declare id: number
  declare name: string
  declare color: string

  declare crop_types: CropType[]
}
