import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsToMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany'
import CropCollectionCropTag from './CropCollectionCropTag'
import CropCollection from './CropCollection'

export default class CropTag extends Model<{
  id: number
  created_at: string
  updated_at: string
  name: string
  color: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'crop_tags'
  }

  public crop_collections (): BelongsToMany<CropCollection> {
    return new BelongsToMany(CropCollection, this)
  }
}
