/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/Address.json'

export default class Address extends Model {
  static entity = 'addresses'

  static primaryKey = 'id'

  entity = 'addresses'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      type: this.string(null),
      number: this.string(null),
      addressable_type: this.string(null),
      addressable_id: this.number(null),
      is_primary: this.boolean(null),
      address_1: this.string(null),
      address_2: this.string(null),
      city: this.string(null),
      state: this.string(null),
      postcode: this.string(null),
      country: this.string(null),

    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare type: string
  declare number: string
  declare addressable_type: string
  declare addressable_id: number
  declare is_primary: string
  declare address_1: string
  declare address_2: string
  declare city: string
  declare state: string
  declare postcode: string
  declare country: string
}
