/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/QueableJob.json'

import User from './User'

export default class QueableJob extends Model {
  static entity = 'queable_jobs'

  static primaryKey = 'id'

  entity = 'queable_jobs'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      selected: this.boolean(null),
      description: this.string(null),
      status: this.string(null),
      error_message: this.string(null),
      user_id: this.number(null),

      user: this.belongsTo(User, 'user_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare selected: string
  declare description: string
  declare status: string
  declare error_message: string
  declare user_id: number

  declare user: User
}
