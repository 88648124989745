/* eslint-disable camelcase */
import { defineStore } from 'pinia'

export const useGlobalSettings = defineStore('globalSettings', {
  state: () => ({
    visible_fallow_types: ['Short Fallow', 'Long Fallow', 'Double Crop'],

    fallow_types: ['Short Fallow', 'Long Fallow', 'Double Crop'],

    visible_stock_types: ['Sheep', 'Cattle'],

    stock_types: ['Sheep', 'Cattle'],

    show_pre_2013: false,

    preCollectionFetched: false,

    dataSiloFetched: false,

    accountSettingsFetched: false,
    popupVideoId: undefined,
  }),
  getters: {

  },
  actions: {
    toggleShowOldData () {
      this.show_pre_2013 = !this.show_pre_2013
    },

    /**
     * Add a fallow type (e.g. Long Fallow, Short Fallow, Double Crop) from
     * the list of visible fallow types
     * @param {String} fallow_type
     */
    addVisibleFallowType (fallow_type) {
      if (!this.visible_fallow_types.includes(fallow_type)) { this.visible_fallow_types.push(fallow_type) }
    },

    /**
     * Remove a fallow type (e.g. Long Fallow, Short Fallow, Double Crop) from
     * the list of visible fallow types
     * @param {String} fallow_type
     */
    removeVisibleFallowType (fallow_type) {
      if (this.visible_fallow_types.includes(fallow_type)) {
        const index = this.visible_fallow_types.findIndex(
          type => type === fallow_type,
        )
        this.visible_fallow_types.splice(index, 1)
      }
    },

    /**
     * Toggle a fallow type (e.g. Long Fallow, Short Fallow, Double Crop) in
     * the list of visible fallow types
     * @param {String} fallow_type
     */
    toggleVisibleFallowType (fallow_type) {
      if (this.visible_fallow_types.includes(fallow_type)) { this.removeVisibleFallowType(fallow_type) } else { this.addVisibleFallowType(fallow_type) }
    },

    /**
     * Add a stock type from
     * the list of visible stock types
     * @param {String} stock_type
     */
    addVisibleStockType (stock_type) {
      if (!this.visible_stock_types.includes(stock_type)) { this.visible_stock_types.push(stock_type) }
    },

    /**
     * Remove a stock type from
     * the list of visible stock types
     * @param {String} stock_type
     */
    removeVisibleStockType (stock_type) {
      if (this.visible_stock_types.includes(stock_type)) {
        const index = this.visible_stock_types.findIndex(
          type => type === stock_type,
        )
        this.visible_stock_types.splice(index, 1)
      }
    },

    /**
     * Toggle a stock type in
     * the list of visible stock types
     * @param {String} stock_type
     */
    toggleVisibleStockType (stock_type) {
      if (this.visible_stock_types.includes(stock_type)) { this.removeVisibleStockType(stock_type) } else { this.addVisibleStockType(stock_type) }
    },
  },
})
