import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import WorkCategory from './WorkCategory'
import Bill from './Bill'
import User from './User'

export default class EmployeeBill extends Model<{
  id: number
  created_at: string
  updated_at: string
  bill_id: number
  employee_id: number
  work_category_id: number
  amount: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'employee_bills'
  }

  public work_category (): BelongsTo<WorkCategory> {
    return new BelongsTo(WorkCategory, this)
  }

  public bill (): BelongsTo<Bill> {
    return new BelongsTo(Bill, this)
  }

  public employee (): BelongsTo<User> {
    return new BelongsTo(User, this)
  }
}
