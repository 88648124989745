import { CropCollection as Model } from '../models'
import { orionCropCollection as Api } from '..'
import { repoFactory } from '../repositories/GenericRepository'
import { useRepo } from 'pinia-orm'

export function useCropCollectionRepo () {
  return useRepo(repoFactory(Model, Api))
}

export function fetchCropCollection () {
  return useCropCollectionRepo().fetch()
}
