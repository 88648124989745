/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/Project.json'

import Farm from './Farm'
import User from './User'
import Period from './Period'
import ProductProject from './ProductProject'
import Product from './Product'
import ProfitFocusGroup from './ProfitFocusGroup'
import ProjectStatus from './ProjectStatus'
import Bill from './Bill'
import Expense from './Expense'
import Business from './Business'

export default class Project extends Model {
  static entity = 'projects'

  static primaryKey = 'id'

  entity = 'projects'

  static fields () {
    return {
      id: this.number(null),
      title: this.string(null),
      estimated_completion_date: this.attr(null),
      collection_date: this.attr(null),
      validation_date: this.attr(null),
      archived: this.boolean(null),
      customer_id: this.number(null),
      details_id: this.number(null),
      collector_id: this.number(null),
      owner_id: this.number(null),
      validator_id: this.number(null),
      project_status_id: this.number(null),
      profit_focus_group_id: this.number(null),
      fee: this.number(null),
      po_number: this.number(null),
      business_id: this.number(null),
      period_id: this.number(null),
      notes: this.attr(null),
      dueFees: this.number(null),
      amountInvoicedPercentage: this.number(null),
      amountBilled: this.number(null),
      outstandingBilling: this.number(null),
      totalExpenses: this.number(null),

      farm: this.belongsTo(Farm, 'details_id', 'farm_id'),
      collector: this.belongsTo(User, 'collector_id'),
      period: this.belongsTo(Period, 'period_id'),
      owner: this.belongsTo(User, 'owner_id'),
      validator: this.belongsTo(User, 'validator_id'),
      products: this.belongsToMany(Product, ProductProject, 'project_id', 'product_id'),
      customer: this.belongsTo(User, 'customer_id'),
      profit_focus_group: this.belongsTo(ProfitFocusGroup, 'profit_focus_group_id'),
      project_status: this.belongsTo(ProjectStatus, 'project_status_id'),
      bills: this.morphMany(Bill, 'billable_id', 'billable_type'),
      expenses: this.morphMany(Expense, 'expensable_id', 'expensable_type'),
      business: this.belongsTo(Business, 'business_id'),
    }
  }

  declare id: number
  declare title: string
  declare estimated_completion_date: string
  declare collection_date: string
  declare validation_date: string
  declare archived: string
  declare customer_id: number
  declare details_id: number
  declare collector_id: number
  declare owner_id: number
  declare validator_id: number
  declare project_status_id: number
  declare profit_focus_group_id: number
  declare fee: number
  declare po_number: number
  declare business_id: number
  declare period_id: number
  declare notes: string
  declare dueFees: number
  declare amountInvoicedPercentage: number
  declare amountBilled: number
  declare outstandingBilling: number
  declare totalExpenses: number

  declare farm: Farm
  declare collector: User
  declare period: Period
  declare owner: User
  declare validator: User
  declare products: Product[]
  declare customer: User
  declare profit_focus_group: ProfitFocusGroup
  declare project_status: ProjectStatus
  declare bills: Bill[]
  declare expenses: Expense[]
  declare business: Business
}
