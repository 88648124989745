import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import CropCollection from './CropCollection'
import Variety from './Variety'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import CropCategory from './CropCategory'

export default class CropType extends Model<{
  id: number
  created_at: string
  updated_at: string
  name: string
  color: string
  options: string
  harvest_type_id: number
  category_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'crop_types'
  }

  public crop_category (): BelongsTo<CropCategory> {
    return new BelongsTo(CropCategory, this)
  }

  public crop_collections (): HasMany<CropCollection> {
    return new HasMany(CropCollection, this)
  }

  public varieties (): HasMany<Variety> {
    return new HasMany(Variety, this)
  }
}
