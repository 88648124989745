import { Farm as Model } from '../models'
import { orionFarm as Api } from '..'
import { repoFactory } from '../repositories/GenericRepository'
import { useRepo } from 'pinia-orm'

export function useFarmRepo () {
  return useRepo(repoFactory(Model, Api))
}

export function fetchFarm () {
  return useFarmRepo().fetch()
}
