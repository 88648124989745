import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import Expense from './Expense'

export default class ExpenseCategory extends Model<{
  id: number
  created_at: string
  updated_at: string
  title: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'expense_categories'
  }

  public expense (): HasMany<Expense> {
    return new HasMany(Expense, this)
  }
}
