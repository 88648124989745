import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import AssetType from './AssetType'
import LivestockSpeciesType from './LivestockSpeciesType'
import LivestockPurpose from './LivestockPurpose'

export default class LivestockEnterprise extends Model<{
  id: number
  created_at: string
  updated_at: string
  asset_type_id: number
  species_id: number
  purpose_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'livestock_enterprises'
  }

  public asset_type (): BelongsTo<AssetType> {
    return new BelongsTo(AssetType, this)
  }

  public species (): BelongsTo<LivestockSpeciesType> {
    return new BelongsTo(LivestockSpeciesType, this)
  }

  public purpose (): BelongsTo<LivestockPurpose> {
    return new BelongsTo(LivestockPurpose, this)
  }
}
