import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { HasMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/hasMany'
import Project from './Project'

export default class ProjectStatus extends Model<{
  id: number
  title: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'project_statuses'
  }

  public projects (): HasMany<Project> {
    return new HasMany(Project, this)
  }
}
