/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/Opportunity.json'

import User from './User'
import Product from './Product'

export default class Opportunity extends Model {
  static entity = 'opportunities'

  static primaryKey = 'id'

  entity = 'opportunities'

  static fields () {
    return {
      id: this.number(null),
      title: this.string(null),
      employee_id: this.number(null),
      product_id: this.number(null),
      rating: this.number(null),
      open_date: this.attr(null),
      estimated_close_date: this.attr(null),
      target_fees: this.number(null),
      probability: this.number(null),
      closed: this.boolean(null),
      last_contact: this.attr(null),
      notes: this.attr(null),
      action_required: this.boolean(null),
      user_id: this.number(null),

      employee: this.belongsTo(User, 'employee_id'),
      product: this.belongsTo(Product, 'product_id'),
      user: this.belongsTo(User, 'user_id'),
    }
  }

  declare id: number
  declare title: string
  declare employee_id: number
  declare product_id: number
  declare rating: number
  declare open_date: string
  declare estimated_close_date: string
  declare target_fees: number
  declare probability: number
  declare closed: string
  declare last_contact: string
  declare notes: string
  declare action_required: string
  declare user_id: number

  declare employee: User
  declare product: Product
  declare user: User
}
