/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/SowingCollectionEvent.json'

import CropCollection from './CropCollection'
import Variety from './Variety'

import { Temporal } from 'temporal-polyfill'
import { DateCast } from '../../casts'

export default class SowingCollectionEvent extends Model {
  static entity = 'sowing_collection_events'

  static primaryKey = 'id'

  entity = 'sowing_collection_events'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      occurrence: this.number(null),
      crop_collection_id: this.number(null),
      variety_id: this.number(null),
      secondary_variety_id: this.number(null),
      sowing_area: this.number(null),
      sowing_date: this.attr(null),
      sowing_rate: this.number(null),
      row_spacing: this.number(null),
      farm_id: this.number(null),
      period_id: this.number(null),
      date: this.attr(null),

      crop_collection: this.belongsTo(CropCollection, 'crop_collection_id'),
      variety: this.belongsTo(Variety, 'variety_id'),
      secondary_variety: this.belongsTo(Variety, 'secondary_variety_id'),
    }
  }

  get sowingDateObject () {
    return this.sowing_date ? Temporal.Instant.from(this.sowing_date).toZonedDateTimeISO(Temporal.Now.timeZone()) : undefined
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare occurrence: number
  declare crop_collection_id: number
  declare variety_id: number | null
  declare secondary_variety_id: number
  declare sowing_area: number
  declare sowing_date: string
  declare sowing_rate: number
  declare row_spacing: number
  declare farm_id: number
  declare period_id: number
  declare date: string

  declare crop_collection: CropCollection
  declare variety: Variety
  declare secondary_variety: Variety
}
