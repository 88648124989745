import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsTo } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo'
import CropCollection from './CropCollection'

export default class LintCollectionEvent extends Model<{
  id: number
  created_at: string
  updated_at: string
  harvested_area: number
  harvested_date: string
  lint_bales: number
  seed_tonnes: number
  occurrence: number
  crop_collection_id: number
  farm_id: number
  period_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'lint_collection_events'
  }

  public crop_collection (): BelongsTo<CropCollection> {
    return new BelongsTo(CropCollection, this)
  }
}
