import { defineStore } from 'pinia'
import { useAppStore } from 'src/stores/appStore'
import { useFilterStore } from '@agripath/common-vue-components'

import { Paddock, usePaddockRepo, orionPaddock, usePaddockPriorPaddockRepo, orionPaddockPriorPaddock, useCropCollectionRepo } from '@agripath/orm'

export const usePaddockStore = defineStore('paddock', () => {
  const app = useAppStore()
  const repo = usePaddockRepo()
  const priorRepo = usePaddockPriorPaddockRepo()
  const cropCollectionRepo = useCropCollectionRepo()

  const filter = useFilterStore()
  const { assetTypeIds, propertyIds, cropTypeIds } = storeToRefs(filter)

  const fetchPaddocks = async (withAncestors = true) => {
    repo.flush()
    let query = orionPaddock.$query().scope('PeriodIdIn', [[app.currentPeriodId, app.priorPeriod?.id]]).sortBy('name')
    if (withAncestors) {
      query = query.with(['ancestors.crop_collections'])
    }

    const response = await query.search(0)
    const paddockAttributes = response.map(m => m.$attributes)
    if (withAncestors) {
      const ancestorPaddocks = paddockAttributes.flatMap(m => m.ancestors)

      const cyclePaddocks = ancestorPaddocks.filter(f => f.is_cycle)
      if (cyclePaddocks.length > 0) {
        console.warn(cyclePaddocks)
      }

      const ancestorCropCollections = ancestorPaddocks.filter(f => !f.is_cycle).flatMap(m => m.crop_collections)
      const currentPaddocks = paddockAttributes.map(({ ancestors, ...keepAttrs }) => { return { ...keepAttrs, ancestorIds: ancestors.map(m => m.id) } })

      repo.insert(ancestorPaddocks)
      cropCollectionRepo.insert(ancestorCropCollections)
      repo.insert(currentPaddocks)
    } else {
      repo.insert(paddockAttributes)
    }
  }

  const fetchPriorPaddocks = async () => {
    priorRepo.flush()
    const priorResponse = await orionPaddockPriorPaddock.$query()
      .scope('PeriodIdIn', [[app.currentPeriodId]])
      .search(0)
    priorRepo.insert(priorResponse.map(m => m.$attributes))
  }

  const fetch = async () => {
    const paddockFetch = fetchPaddocks()
    const priorFetch = fetchPriorPaddocks()
    await Promise.all([
      paddockFetch,
      priorFetch,
    ])
  }

  const persistUpdate = async (paddock: Paddock) => {
    const response = await orionPaddock.$query().update(paddock.id, paddock.$getAttributes() as orionPaddock['$attributes'])
    repo.where('id', paddock.id).update(response.$attributes)
    return response
  }

  const persistCreate = async (paddock: Paddock) => {
    const response = await orionPaddock.$query().store(paddock.$getAttributes() as orionPaddock['$attributes'])
    repo.insert(response.$attributes)
    return response
  }

  const persist = async (paddock: Paddock, priorPaddocks?: Paddock[]) => {
    let response: orionPaddock
    if (paddock.id) {
      response = await persistUpdate(paddock)
    } else {
      response = await persistCreate(paddock)
    }
    if (priorPaddocks) {
      const relationResponse = await response.prior_paddocks().sync(priorPaddocks.map(m => m.id))
      if (relationResponse.attached.length) {
        const pivotRecords = relationResponse.attached.map((attachedId) => {
          return {
            prior_paddock_id: attachedId,
            parent_paddock_id: paddock.id,
          }
        })

        priorRepo.insert(pivotRecords)
      }
      if (relationResponse.detached.length) {
        for (const detachedPaddockId of relationResponse.detached) {
          priorRepo.query().where('parent_paddock_id', paddock.id).where('prior_paddock_id', detachedPaddockId).delete()
        }
      }
    }
  }

  const batchUpdate = async (paddocks: Paddock[], updateData: Paddock) => {
    const resources: { [s: number]: Paddock } = {}
    const dataAttributes = updateData.$getAttributes()
    for (const paddock of paddocks) {
      resources[paddock.id] = { id: paddock.id } as Paddock
      for (const field in dataAttributes) {
        if (dataAttributes[field] != null && paddock[field] !== dataAttributes[field]) {
          resources[paddock.id][field] = dataAttributes[field]
        }
      }
    }
    await repo.persist(Object.values(resources))
  }

  const query = (withoutScope = false) => {
    const query = repo.query()
    if (withoutScope) {
      return query
    }
    return query
      .where('period_id', app.currentPeriodId)
      .where('farm_id', app.currentFarmId)
  }

  const filteredQuery = () => {
    const filterQuery = query()
      .where(paddock => assetTypeIds.value.has(paddock.asset_type_id))
      .where(paddock => propertyIds.value.has(paddock.property_id))
    if (cropTypeIds.value.size > 0) {
      filterQuery.whereHas('crop_collections', (query) => {
        query.where(cc => cropTypeIds.value.has(cc.current_crop_id))
      })
    }

    return filterQuery
  }

  const priorQuery = () => {
    if (app.priorPeriod) {
      return repo.query()
        .where('period_id', app.priorPeriod?.id)
        .where('farm_id', app.currentFarmId)
    }
  }

  const make = (record?: Partial<orionPaddock['$attributes']>) => {
    const farmPeriod = { period_id: app.currentPeriodId, farm_id: app.currentFarmId }
    return repo.make({ ...record, ...farmPeriod })
  }

  return {
    fetch,
    persist,
    query,
    filteredQuery,
    priorQuery,
    make,
    batchUpdate,
  }
})
