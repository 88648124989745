/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/BusinessFarm.json'

export default class BusinessFarm extends Model {
  static entity = 'business_farm'

  static primaryKey = ['business_id', 'farm_id']

  entity = 'business_farm'

  static fields () {
    return {
      id: this.number(null),
      business_id: this.number(null),
      farm_id: this.number(null),

    }
  }

  declare id: number
  declare business_id: number
  declare farm_id: number
}
