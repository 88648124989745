/* eslint-disable camelcase */

import { Model } from '../../types'

import Farm from './Farm'
import LivestockEnterprise from './LivestockEnterprise'
import Period from './Period'

export default class FarmLivestockEnterprisePeriod extends Model {
  static entity = 'farm_livestock_enterprise_periods'

  static primaryKey = 'id'

  entity = 'farm_livestock_enterprise_periods'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      farm_id: this.number(null),
      livestock_enterprise_id: this.number(null),
      period_id: this.number(null),

      farm: this.belongsTo(Farm, 'farm_id'),
      livestock_enterprise: this.belongsTo(LivestockEnterprise, 'livestock_enterprise_id'),
      period: this.belongsTo(Period, 'period_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare farm_id: number
  declare livestock_enterprise_id: number
  declare period_id: number

  declare farm: Farm
  declare livestock_enterprise: LivestockEnterprise
  declare period: Period
}
