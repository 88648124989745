/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/CropCollectionFallowType.json'

import CropCollectionEnterprise from './CropCollectionEnterprise'

export default class CropCollectionFallowType extends Model {
  static entity = 'crop_collection_fallow_types'

  static primaryKey = 'id'

  entity = 'crop_collection_fallow_types'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      name: this.string(null),

      crop_collection_enterprises: this.hasMany(CropCollectionEnterprise, 'fallow_type_id'),
      crop_collections: this.hasMany(CropCollectionEnterprise, 'fallow_type_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare name: string

  declare crop_collection_enterprises: CropCollectionEnterprise[]
  declare crop_collections: CropCollectionEnterprise[]
}
