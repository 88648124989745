/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/Period.json'
// import { DateCast } from 'pinia-orm/dist/casts'

import CropCollection from './CropCollection'
import LivestockInventoryCollection from './LivestockInventoryCollection'
import LivestockFeedCollection from './LivestockFeedCollection'
import LivestockAgistmentCollection from './LivestockAgistmentCollection'
import LivestockAgistedAwayCollection from './LivestockAgistedAwayCollection'
import LivestockBreedingDetailsCollection from './LivestockBreedingDetailsCollection'
import LivestockPurchasesCollection from './LivestockPurchasesCollection'
import LivestockSalesCollection from './LivestockSalesCollection'
import LivestockWoolCollection from './LivestockWoolCollection'
import NewPlantPurchase from './NewPlantPurchase'
import SoldPlantSale from './SoldPlantSale'
import Paddock from './Paddock'
import CropTypeFarmPeriod from './CropTypeFarmPeriod'
import CropType from './CropType'
import FarmPeriod from './FarmPeriod'
import Farm from './Farm'
import FarmLivestockEnterprisePeriod from './FarmLivestockEnterprisePeriod'
import { Temporal } from 'temporal-polyfill'

import { DateCast } from '../../casts'

export default class Period extends Model {
  static entity = 'periods'

  static primaryKey = 'id'

  entity = 'periods'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(null),
      year_name: this.string(null),
      start_date: this.attr(null),
      end_date: this.attr(null),
      sowing_start_date: this.attr(null),
      sowing_end_date: this.attr(null),

      crop_collections: this.hasMany(CropCollection, 'period_id'),
      livestock_inventory_collections: this.hasMany(LivestockInventoryCollection, 'period_id'),
      livestock_feed_collections: this.hasMany(LivestockFeedCollection, 'period_id'),
      livestock_agistment_collections: this.hasMany(LivestockAgistmentCollection, 'period_id'),
      livestock_agisted_away_collections: this.hasMany(LivestockAgistedAwayCollection, 'period_id'),
      livestock_breeding_details_collections: this.hasMany(LivestockBreedingDetailsCollection, 'period_id'),
      livestock_purchases_collections: this.hasMany(LivestockPurchasesCollection, 'period_id'),
      livestock_sales_collections: this.hasMany(LivestockSalesCollection, 'period_id'),
      livestock_wool_collections: this.hasMany(LivestockWoolCollection, 'period_id'),
      new_plant_purchases: this.hasMany(NewPlantPurchase, 'period_id'),
      sold_plant_sales: this.hasMany(SoldPlantSale, 'period_id'),
      paddocks: this.hasMany(Paddock, 'period_id'),
      crop_types: this.belongsToMany(CropType, CropTypeFarmPeriod, 'period_id', 'crop_type_id'),
      farms: this.belongsToMany(Farm, FarmPeriod, 'period_id', 'farm_id'),
      farm_livestock_enterprise_periods: this.hasMany(FarmLivestockEnterprisePeriod, 'period_id'),
    }
  }

  static casts () {
    return {
      start_date: DateCast,
      end_date: DateCast,
      sowing_start_date: DateCast,
      sowing_end_date: DateCast,
    }
  }

  declare id: number
  declare name: string
  declare year_name: string
  declare start_date: Temporal.ZonedDateTime
  declare end_date: Temporal.ZonedDateTime
  declare sowing_start_date: Temporal.ZonedDateTime
  declare sowing_end_date: Temporal.ZonedDateTime

  declare crop_collections: CropCollection[]
  declare livestock_inventory_collections: LivestockInventoryCollection[]
  declare livestock_feed_collections: LivestockFeedCollection[]
  declare livestock_agistment_collections: LivestockAgistmentCollection[]
  declare livestock_agisted_away_collections: LivestockAgistedAwayCollection[]
  declare livestock_breeding_details_collections: LivestockBreedingDetailsCollection[]
  declare livestock_purchases_collections: LivestockPurchasesCollection[]
  declare livestock_sales_collections: LivestockSalesCollection[]
  declare livestock_wool_collections: LivestockWoolCollection[]
  declare new_plant_purchases: NewPlantPurchase[]
  declare sold_plant_sales: SoldPlantSale[]
  declare paddocks: Paddock[]
  declare crop_types: CropType[]
  declare farms: Farm[]
  declare farm_livestock_enterprise_periods: FarmLivestockEnterprisePeriod[]
}
