import { CastAttribute } from 'pinia-orm'
import { Temporal } from 'temporal-polyfill'

export class DateCast extends CastAttribute {
  get (value: string | null) {
    if (value === null) {
      return null
    }
    if (typeof value === 'string') {
      return Temporal.Instant.from(value).toZonedDateTimeISO(Temporal.Now.timeZone())
    } else {
      return value
    }
  }

  /**
   * Make the value for the attribute.
   */

  set (value: string | Temporal.ZonedDateTime | null) {
    if (value === null) {
      return null
    }
    if (typeof value === 'string') {
      return value
    } else {
      return value.toString()
    }
  }
}
