/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/ExtensiveLivestock.json'

import Farm from './Farm'

export default class ExtensiveLivestock extends Model {
  static entity = 'extensive_livestocks'

  static primaryKey = 'id'

  entity = 'extensive_livestocks'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      farm_id: this.number(null),
      Zone: this.string(null),
      Stock: this.string(null),
      DSE: this.number(null),
      Year: this.string(null),
      Change_Value: this.number(null),
      Sales: this.number(null),
      Purchases: this.number(null),
      TradingProfit: this.number(null),
      Wool_Income: this.number(null),
      Semen_Income: this.number(null),
      Other_Income: this.number(null),
      GrossIncome: this.number(null),
      GrossMargin: this.number(null),
      GM_perDSE: this.number(null),
      GM_less_wagedepn: this.number(null),
      GM_less_PE: this.number(null),
      GM_Ha: this.number(null),
      DSEperha: this.number(null),
      Exp_Seed: this.number(null),
      Exp_ChemicalFallow: this.number(null),
      Exp_ChemicalCrop: this.number(null),
      Exp_ContractingFallow: this.number(null),
      Exp_ContractingSpraying: this.number(null),
      Exp_ContractingFarming: this.number(null),
      Exp_ContractingHarvesting: this.number(null),
      Exp_FertiliserN: this.number(null),
      Exp_FertiliserP: this.number(null),
      Exp_FertiliserOther: this.number(null),
      Exp_WaterPumping: this.number(null),
      Exp_WaterExpenses: this.number(null),
      Exp_AnimalHealth: this.number(null),
      Exp_LivestockContractServices: this.number(null),
      Exp_LivestockFeedAgistment: this.number(null),
      Exp_CasualLabour: this.number(null),
      Exp_FreightCartage: this.number(null),
      Exp_FuelLube: this.number(null),
      Exp_Insurance: this.number(null),
      Exp_RMPlant: this.number(null),
      Exp_RMIrrigation: this.number(null),
      Exp_RMStructures: this.number(null),
      Exp_SellingCost: this.number(null),
      Exp_Ginning: this.number(null),
      Exp_GMOLicense: this.number(null),
      Exp_Packaging: this.number(null),
      Exp_Sundry: this.number(null),
      Exp_Wages: this.number(null),
      Exp_Depreciation: this.number(null),
      Exp_CropsGrazed: this.number(null),
      Exp_OnHandFeed: this.number(null),
      Exp_PastureCosts: this.number(null),
      Exp_Total: this.number(null),
      TPMLCosts: this.number(null),
      AreaCosts: this.number(null),
      YieldCosts: this.number(null),
      StockingRate: this.number(null),
      Mortality: this.number(null),
      OffspringPercent: this.number(null),
      WeaningPercent: this.number(null),
      Survivability: this.number(null),
      MeatProduced_ha: this.number(null),
      MeatProduced_100mm: this.number(null),
      MeatSold_ha: this.number(null),
      MeatSold_100mm: this.number(null),
      AveValueStockSold_Head: this.number(null),
      AveValueStockSold_Kg: this.number(null),
      AveValueStockPurchased_Head: this.number(null),
      AveValueStockPurchased_Kg: this.number(null),
      ProductionCost: this.number(null),
      DSE_labour: this.number(null),
      Land_HighPerformance: this.number(null),
      Land_Improved: this.number(null),
      Land_SemiImproved: this.number(null),
      Land_Native: this.number(null),
      Land_Waste: this.number(null),
      Land_Total: this.number(null),
      Plant_Used: this.number(null),
      Labour_Months: this.number(null),
      P_removed: this.number(null),
      P_replaced: this.number(null),
      P_deficit: this.number(null),
      Wool_shorn: this.number(null),
      Wool_Cut_kg: this.number(null),
      Wool_micron: this.number(null),
      Wool_yield: this.number(null),
      Wool_strength: this.number(null),
      Wool_VM: this.number(null),
      Wool_Kgsperhd: this.number(null),
      Wool_cperkg_clean: this.number(null),
      Wool_hd: this.number(null),
      Wool_kgsperha: this.number(null),
      AWool_shorn: this.number(null),
      Awool_Cut_kg: this.number(null),
      Awool_micron: this.number(null),
      Awool_yield: this.number(null),
      Awool_strength: this.number(null),
      AWool_VM: this.number(null),
      DSE_July: this.number(null),
      DSE_August: this.number(null),
      DSE_September: this.number(null),
      DSE_October: this.number(null),
      DSE_November: this.number(null),
      DSE_December: this.number(null),
      DSE_January: this.number(null),
      DSE_Febuary: this.number(null),
      DSE_March: this.number(null),
      DSE_April: this.number(null),
      DSE_May: this.number(null),
      DSE_June: this.number(null),
      Change_Value_DSE: this.number(null),
      Sales_DSE: this.number(null),
      Purchases_DSE: this.number(null),
      Zone2: this.number(null),
      Type: this.number(null),
      Exp_ChemicalOther1: this.number(null),
      Exp_ChemicalOther2: this.number(null),
      Exp_ContractingOther: this.number(null),
      Exp_FertiliserK: this.number(null),
      Exp_FertiliserOther2: this.number(null),
      Exp_FertiliserOther3: this.number(null),
      Exp_WaterTempPurchases: this.number(null),
      Exp_RMLivestock: this.number(null),
      Exp_Processing: this.number(null),
      Exp_Agronomy: this.number(null),
      Exp_Sundry2: this.number(null),
      Exp_Sundry3: this.number(null),
      FeedCosts: this.number(null),
      DSE_February: this.number(null),

      farm: this.belongsTo(Farm, 'farm_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare farm_id: number
  declare Zone: string
  declare Stock: string
  declare DSE: number
  declare Year: string
  declare Change_Value: number
  declare Sales: number
  declare Purchases: number
  declare TradingProfit: number
  declare Wool_Income: number
  declare Semen_Income: number
  declare Other_Income: number
  declare GrossIncome: number
  declare GrossMargin: number
  declare GM_perDSE: number
  declare GM_less_wagedepn: number
  declare GM_less_PE: number
  declare GM_Ha: number
  declare DSEperha: number
  declare Exp_Seed: number
  declare Exp_ChemicalFallow: number
  declare Exp_ChemicalCrop: number
  declare Exp_ContractingFallow: number
  declare Exp_ContractingSpraying: number
  declare Exp_ContractingFarming: number
  declare Exp_ContractingHarvesting: number
  declare Exp_FertiliserN: number
  declare Exp_FertiliserP: number
  declare Exp_FertiliserOther: number
  declare Exp_WaterPumping: number
  declare Exp_WaterExpenses: number
  declare Exp_AnimalHealth: number
  declare Exp_LivestockContractServices: number
  declare Exp_LivestockFeedAgistment: number
  declare Exp_CasualLabour: number
  declare Exp_FreightCartage: number
  declare Exp_FuelLube: number
  declare Exp_Insurance: number
  declare Exp_RMPlant: number
  declare Exp_RMIrrigation: number
  declare Exp_RMStructures: number
  declare Exp_SellingCost: number
  declare Exp_Ginning: number
  declare Exp_GMOLicense: number
  declare Exp_Packaging: number
  declare Exp_Sundry: number
  declare Exp_Wages: number
  declare Exp_Depreciation: number
  declare Exp_CropsGrazed: number
  declare Exp_OnHandFeed: number
  declare Exp_PastureCosts: number
  declare Exp_Total: number
  declare TPMLCosts: number
  declare AreaCosts: number
  declare YieldCosts: number
  declare StockingRate: number
  declare Mortality: number
  declare OffspringPercent: number
  declare WeaningPercent: number
  declare Survivability: number
  declare MeatProduced_ha: number
  declare MeatProduced_100mm: number
  declare MeatSold_ha: number
  declare MeatSold_100mm: number
  declare AveValueStockSold_Head: number
  declare AveValueStockSold_Kg: number
  declare AveValueStockPurchased_Head: number
  declare AveValueStockPurchased_Kg: number
  declare ProductionCost: number
  declare DSE_labour: number
  declare Land_HighPerformance: number
  declare Land_Improved: number
  declare Land_SemiImproved: number
  declare Land_Native: number
  declare Land_Waste: number
  declare Land_Total: number
  declare Plant_Used: number
  declare Labour_Months: number
  declare P_removed: number
  declare P_replaced: number
  declare P_deficit: number
  declare Wool_shorn: number
  declare Wool_Cut_kg: number
  declare Wool_micron: number
  declare Wool_yield: number
  declare Wool_strength: number
  declare Wool_VM: number
  declare Wool_Kgsperhd: number
  declare Wool_cperkg_clean: number
  declare Wool_hd: number
  declare Wool_kgsperha: number
  declare AWool_shorn: number
  declare Awool_Cut_kg: number
  declare Awool_micron: number
  declare Awool_yield: number
  declare Awool_strength: number
  declare AWool_VM: number
  declare DSE_July: number
  declare DSE_August: number
  declare DSE_September: number
  declare DSE_October: number
  declare DSE_November: number
  declare DSE_December: number
  declare DSE_January: number
  declare DSE_Febuary: number
  declare DSE_March: number
  declare DSE_April: number
  declare DSE_May: number
  declare DSE_June: number
  declare Change_Value_DSE: number
  declare Sales_DSE: number
  declare Purchases_DSE: number
  declare Zone2: number
  declare Type: number
  declare Exp_ChemicalOther1: number
  declare Exp_ChemicalOther2: number
  declare Exp_ContractingOther: number
  declare Exp_FertiliserK: number
  declare Exp_FertiliserOther2: number
  declare Exp_FertiliserOther3: number
  declare Exp_WaterTempPurchases: number
  declare Exp_RMLivestock: number
  declare Exp_Processing: number
  declare Exp_Agronomy: number
  declare Exp_Sundry2: number
  declare Exp_Sundry3: number
  declare FeedCosts: number
  declare DSE_February: number

  declare farm: Farm
}
