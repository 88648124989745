import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'

export default class LivestockWoolCollection extends Model<{
  id: number
  created_at: string
  updated_at: string
  farm_id: number
  year: string
  enterprise: string
  class_of_stock: string
  number: number
  month_shorn: number
  wool_cut: number
  gross_wool: number
  micron: number
  yield_percentage: number
  strength: number
  vm_percentage: number
  length: number
  notes: string
  period_id: number
  months_since_last_sheering: number
  enterprise_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'livestock_wool_collections'
  }
}
