/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/ExpenseCategory.json'

import Expense from './Expense'

export default class ExpenseCategory extends Model {
  static entity = 'expense_categories'

  static primaryKey = 'id'

  entity = 'expense_categories'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      title: this.string(null),

      expense: this.hasMany(Expense, 'expense_category_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare title: string

  declare expense: Expense[]
}
