/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/Variety.json'

import CropType from './CropType'

export default class Variety extends Model {
  static entity = 'varieties'

  static primaryKey = 'id'

  entity = 'varieties'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      name: this.string(null),
      crop_type_id: this.number(null),
      validated: this.boolean(null),

      crop_type: this.belongsTo(CropType, 'crop_type_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare name: string
  declare crop_type_id: number
  declare validated: string

  declare crop_type: CropType
}
