import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'

export default class Geolocation extends Model<{
  name: string
  location: string
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'geolocations'
  }
}
