import { Model } from '@tailflow/laravel-orion/lib/model'
import type { DefaultPersistedAttributes } from '@tailflow/laravel-orion/lib/types/defaultPersistedAttributes'
import { BelongsToMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany'
import FileFileTag from './FileFileTag'
import FileTag from './FileTag'

export default class File extends Model<{
  id: number
  created_at: string
  updated_at: string
  name: string
  description: string
  path: string
  fileable_type: string
  fileable_id: number
  period_id: number
},
DefaultPersistedAttributes > {
  public $resource (): string {
    return 'files'
  }

  public tags (): BelongsToMany<FileTag> {
    return new BelongsToMany(FileTag, this)
  }
}
