/* eslint-disable camelcase */

import { Model } from '../../types'
import schema from '../../../schemas/File.json'

import FileFileTag from './FileFileTag'
import FileTag from './FileTag'

export default class File extends Model {
  static entity = 'files'

  static primaryKey = 'id'

  entity = 'files'

  static fields () {
    return {
      id: this.number(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      name: this.string(null),
      description: this.attr(null),
      path: this.string(null),
      fileable_type: this.string(null),
      fileable_id: this.number(null),
      period_id: this.number(null),

      tags: this.belongsToMany(FileTag, FileFileTag, 'file_id', 'file_tag_id'),
    }
  }

  declare id: number
  declare created_at: string
  declare updated_at: string
  declare name: string
  declare description: string
  declare path: string
  declare fileable_type: string
  declare fileable_id: number
  declare period_id: number

  declare tags: FileTag[]
}
